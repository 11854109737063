<template>
  <confirmation-dialog
    v-model="show"
    v-if="show"
    :title="$t('components.workspace.deletion.title', { title: workspace.title })"
    :content="$t('components.workspace.deletion.content')"
    :confirm="$t('components.workspace.deletion.confirm')"
    @confirmed="onConfirmed"
    :pending="pending"
  />
</template>

<script>
import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import { HIDE_ALL_DIALOGS } from '@/store/workspace/mutation-types'
import { SHOW_SNACKBAR } from '@/store/gui/mutation-types'

import deleteGQL from '@/graphql/app/workspace/mutation/delete.gql'

import ConfirmationDialog from '@/components/confirmation-dialog/confirmation-dialog'
import { GET_CURRENT_WORKSPACE, IS_DELETE } from '@/store/workspace/getter-types'

const guiStore = namespace('gui')
const workspaceStore = namespace('workspace')

@Component({
    components: {
        ConfirmationDialog
    }
})
export default class WorkspaceDeleteDialog extends Vue {
  @guiStore.Mutation(SHOW_SNACKBAR) showSnackbar

  @workspaceStore.Getter(IS_DELETE) delete
  @workspaceStore.Getter(GET_CURRENT_WORKSPACE) workspace
  @workspaceStore.Mutation(HIDE_ALL_DIALOGS) hide

  pending = false

  get show () {
      return this.delete && this.workspace
  }

  set show (value) {
      this.hide()
  }

  async onConfirmed () {
      try {
          this.pending = true
          await this.$apollo.mutate({
              mutation: deleteGQL,
              variables: {
                  id: this.workspace.id
              }
          })
          this.$track('Workspace', 'delete')
          this.showSnackbar(this.$t('components.workspace.deletion.success'))
          this.hide()
          await this.$router.push({ name: 'workspace' })
      } catch (error) {
      // eslint-disable-next-line no-console
          console.log('%cError on delete workspace:', 'color: red;', error)
      } finally {
          this.pending = false
      }
  }
}
</script>
