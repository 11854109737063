import Router from 'vue-router'
import routes from './routes'
import hooks from './hooks'

export const router = new Router({
    mode: 'history',
    routes: routes,
    scrollBehavior () {
        return {
            x: 0,
            y: 0
        }
    }
})

hooks.forEach(hook => router.beforeEach(hook))
