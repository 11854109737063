import { adminClient, anonClient, appClient } from '@/plugins/apollo'
import { ability, createRulesByUser } from '@/plugins/casl/ability'
import azureLoginGQL from '@/graphql/anon/user/mutation/azure-login.gql'
import meGQL from '@/graphql/app/user/query/profile.gql'
import loginGQL from '@/graphql/anon/user/mutation/login.gql'
import logoutGQL from '@/graphql/app/user/mutation/logout.gql'
import resetGQL from '@/graphql/anon/password/mutation/submit.gql'
import secondFactorGQL from '@/graphql/anon/user/mutation/secondFactor.gql'
import registrationGQL from '@/graphql/anon/trial/mutation/registration.gql'
import optInGQL from '@/graphql/anon/trial/mutation/opt-in.gql'

export const reset = async (token, passwd) => {
    try {
        const response = await anonClient.mutate({
            mutation: resetGQL,
            variables: {
                input: {
                    confirmationToken: token,
                    password: passwd
                }
            }
        })
        return response.data.password.reset
    } catch (error) {
        console.log('%cError on service.auth.reset: ', 'color:red', error) // eslint-disable-line no-console
        // eslint-disable-next-line no-throw-literal
        throw new Error('service.auth.reset')
    }
}

export const azureLogin = async (code) => {
    try {
        const response = await anonClient.mutate({
            mutation: azureLoginGQL,
            variables: {
                code: code
            }
        })
        return response.data.azureLogin
    } catch (error) {
        console.log('%cError on service.auth.azureLogin: ', 'color:red', error) // eslint-disable-line no-console
        throw new Error(error.graphQLErrors[0].code || -1)
    // throw new Error(`service.auth.azureLogin: ${error.graphQLErrors[0].code || -1}`)
    }
}

export const login = async (credentials) => {
    try {
        const response = await anonClient.mutate({
            mutation: loginGQL,
            variables: {
                input: credentials
            }
        })
        return response.data.login
    } catch (error) {
        console.log('%cError on service.auth.login: ', 'color:red', error) // eslint-disable-line no-console
        throw new Error('service.auth.login')
    }
}

export const secondFactorLogin = async (secondFactorData) => {
    try {
        const response = await anonClient.mutate({
            mutation: secondFactorGQL,
            variables: {
                input: secondFactorData
            }
        })
        return response.data.secondFactor
    } catch (error) {
        console.log('%cError on service.auth.secondFactorLogin: ', 'color:red', error) // eslint-disable-line no-console
        throw new Error('service.auth.secondFactorLogin')
    }
}

export const logout = async (locally = false) => {
    try {
        if (!locally) {
            await appClient.mutate({
                mutation: logoutGQL
            })
        }

        await appClient.cache.reset()
        await adminClient.cache.reset()

        return true
    } catch (error) {
        console.log('%cError on service.auth.logout: ', 'color:red', error) // eslint-disable-line no-console
        throw new Error('service.auth.logout')
    }
}

export const fetchMe = async () => {
    try {
        const me = await appClient.query({
            query: meGQL,
            fetchPolicy: 'no-cache'
        })
        return me.data.profile
    } catch (error) {
        console.log('%cError on service.auth.me: ', 'color:red', error) // eslint-disable-line no-console
        throw new Error('service.auth.me')
    }
}

export const updateRules = (user) => {
    try {
        const rules = createRulesByUser(user)
        ability.update(rules)
        return rules
    } catch (error) {
        console.log('%cError on service.auth.updateRules: ', 'color:red', error) // eslint-disable-line no-console
        throw new Error('service.auth.updateRules')
    }
}

export const clearRules = () => {
    try {
        ability.update([])
    } catch (error) {
        console.log('%cError on service.auth.clearRules: ', 'color:red', error) // eslint-disable-line no-console
        throw new Error('service.auth.clearRules')
    }
}

export const trialRegistration = async (input) => {
    try {
        const response = await anonClient.mutate({
            mutation: registrationGQL,
            variables: {
                input: input
            }
        })
        return response.data.trialRegistration
    } catch (error) {
        console.log('%cError on service.auth.trialRegistration', 'color: red;', error) // eslint-disable-line no-console
        throw Error('E-Mail already in use')
    }
}

export const activateTrial = async (input) => {
    try {
        const response = await anonClient.mutate({
            mutation: optInGQL,
            variables: {
                input: input
            }
        })
        return response.data.activateTrial
    } catch (error) {
        console.log('%cError on service.auth.activateTrial: ', 'color:red', error) // eslint-disable-line no-console
        throw error
    }
}
