import * as GETTER from './getter-types'
import without from 'lodash/without'
import { ROLE_USER } from '@/model/roles'
import dayjs from 'dayjs'
import { PRODUCT } from '@/model/products'

export default {
    [GETTER.IS_AUTHENTICATED]: (state) => state.user.roles.length > 0,
    [GETTER.IS_PENDING]: (state) => state.pending,
    [GETTER.GET_USERNAME]: (state) => state.user.email,
    [GETTER.GET_FULLNAME]: (state) => state.user.firstName + ' ' + state.user.lastName,
    [GETTER.GET_LAST_LOGIN]: (state) => state.user.lastLogin,
    [GETTER.GET_COMPANY]: (state) => state.user.company,
    [GETTER.GET_COMPANY_LOCALES]: (state) => Array.from(state.user.company.locales).sort((a, b) => a.localeCompare(b)),
    [GETTER.GET_COMPANY_FEATURES]: (state) => state.user.company.features,
    [GETTER.GET_AVATAR]: (state) => state.user.avatar,
    [GETTER.GET_USER_ID]: (state) => state.user.id,
    [GETTER.RULES]: (state) => state.rules,
    [GETTER.GET_LOCALE]: (state) => state.locale,
    [GETTER.ROLES]: (state) => state.user.roles,
    [GETTER.IS_EWE_USER]: (state) => {
        return (state.user.company.title === 'EWE' || state.user.company.title === 'EWE IT Radar Company') &&
      state.user.roles.includes(ROLE_USER)
    },
    [GETTER.IS_EWE_COMPANY]: (state) => state.user.company.title === 'EWE' || state.user.company.title === 'EWE IT Radar Company',
    [GETTER.GET_TOGGLE_LOCALE]: (state) => Array.isArray(state.user.company.locales) ? without(state.user.company.locales, state.locale)[0] : null,
    [GETTER.GET_COMPANY_COLOR]: (state) => (state.user && state.user.company && state.user.company.color)
        ? state.user.company.color
        : 'transparent',

    [GETTER.IS_FEATURE_ENABLED]: (state) => (feature) => {
        return state.user.company.features.includes(feature) || false
    },
    [GETTER.TOKEN_IS_VALID]: (state) => () => {
        return dayjs().isBefore(state.tokenExpirationDate)
    },
    [GETTER.TOKEN_EXPIRED]: (state) => () => {
        if (state.tokenExpirationDate === null) {
            return false
        }

        return dayjs().isAfter(state.tokenExpirationDate)
    },
    [GETTER.GET_PUBLIC_INNOVATION_FIELDS_STATE]: (state) => () => {
        if (state.user.company.publicInnovationFields === null) {
            return false
        }

        return state.user.company.publicInnovationFields
    },
    [GETTER.GET_PUBLIC_INNOVATION_PROJECTS_STATE]: (state) => () => {
        if (state.user.company.publicInnovationProjects === null) {
            return false
        }

        return state.user.company.publicInnovationProjects
    },
    [GETTER.GET_ADMIN_ACTS_AS_USER]: (state) => {
        if (!state.user) {
            return false
        }

        return state.user.adminActsAsUser
    },
    [GETTER.SHOW_INTRO_VIDEO]: (state) => {
        if (!state.user) {
            return false
        }

        return state.user.showIntroductionVideo
    },
    [GETTER.IS_TEST_COMPANY]: (state) => {
        if (!state.user || !state.user.company) {
            return true
        }

        return state.user.company.testAccount
    },
    [GETTER.IS_TRENDEXPLORER_COMPANY]: (state) => {
        if (!state.user || !state.user.company) {
            return false
        }

        return state.user.company.product === PRODUCT.TRENDEXPLORER
    },
    [GETTER.GET_EXPORT_TEMPLATES]: (state) => {
        return state.user.company.exportTemplates
    },
    [GETTER.GET_INSIGHTS_UPDATES]: (state) => {
        return state.user.insightsUpdates
    },

    [GETTER.GET_FEATURE_UPDATES]: (state) => {
        if (!state.user) {
            return 0
        }

        return state.user.featureUpdates
    },

    [GETTER.GET_FEATURE_UPDATES_DISABLED]: (state) => {
        if (!state.user) {
            return false
        }

        return state.user.featureUpdatesDisabled
    },

    [GETTER.IS_AUTOMATIC_UNIVERSE_UPDATE]: (state) => state.user &&
    state.user.company && state.user.company.automaticUniverseUpdate,

    [GETTER.GET_TRACKING_ID]: (state) => state.trackingId,

    [GETTER.GET_2FA]: (state) => state.redirectHash
}
