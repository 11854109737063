<template>
  <v-list-group
    v-model="parent.active"
  >
    <v-list-item
      slot="activator"
      dense
    >
      <v-list-item-action>
        <v-checkbox
          :input-value="checked"
          :indeterminate="indeterminate"
          @click.stop.prevent="checked = !checked"
          color="primary"
        />
      </v-list-item-action>
      <v-list-item-title>
        {{ parent.title }}
      </v-list-item-title>
    </v-list-item>
    <template
      v-for="child in sort(parent.children)"
    >
      <child
        v-if="child.visible"
        :entry="child"
        :key="child.id"
        :state="state"
        @addChild="onAddChild"
        @removeChild="onRemoveChild"
      />
    </template>
  </v-list-group>
</template>

<script>
import { Component, Prop, Vue } from 'vue-property-decorator'

import Child from './facet-child'
import { sortFacetMap } from '@/util/Facets'

@Component({
    components: {
        Child
    }
})
export default class SearchFacetParent extends Vue {
  @Prop({
      type: Object,
      default: () => null
  }) parent

  @Prop({
      type: Object,
      default: () => {
      }
  }) state

  add () {
      this.$emit('addParent', this.parent)
  }

  remove () {
      this.$emit('removeParent', this.parent)
  }

  onAddChild (value) {
      this.$emit('addChild', value)
  }

  onRemoveChild (value) {
      this.$emit('removeChild', value)
  }

  sort (map) {
      return sortFacetMap(map)
  }

  get isChecked () {
      const cnt = Object
          .keys(this.parent.children)
          .reduce((acc, cur) => acc + (cur in this.state ? 1 : 0), 0)

      if (cnt === 0) {
          return false
      }

      if (cnt < Object.keys(this.parent.children).length) {
          return null
      }

      return true
  }

  get indeterminate () {
      return this.checked === null
  }

  get checked () {
      return this.isChecked
  }

  set checked (value) {
      const c = this.isChecked
      if (c === true || c === null) {
          this.remove()
      } else {
          this.add()
      }
  }
}
</script>
