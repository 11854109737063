<template>
  <component
    :is="current"
    v-model="internal"
    :microtrend="microtrend"
    :mode="mode"
    :pending="pending"
    @submit="onSubmit"
    @change-mode="onChangeMode"
  />
</template>

<script>
import { Component, Prop, Vue } from 'vue-property-decorator'

import { MICROTRENDS } from '@/model/types'
import { EDIT, LIST } from '@/model/views/modes'

import Edit from './edit'
import View from './view'

@Component
export default class IndustriesWrapper extends Vue {
  @Prop({ type: Array }) value
  @Prop({ type: Boolean, default: () => false }) pending
  @Prop({ type: Object, validator: obj => MICROTRENDS.includes(obj.__typename) }) microtrend

  mode = LIST

  get current () {
      return this.components[this.mode]
  }

  get internal () {
      return this.value
  }

  set internal (value) {
      this.$emit('input', value)
  }

  onChangeMode (value) {
      this.mode = value
  }

  onSubmit () {
      this.$emit('submit')
  }

  created () {
      this.mode = LIST
      this.components = {
          [LIST]: View,
          [EDIT]: Edit
      }
  }
}
</script>
