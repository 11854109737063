import { SnackbarOptions } from '@/store/gui/types'
import { namespacer } from '@/util/namespacer'
import { SHOW_SNACKBAR } from '@/store/gui/mutation-types'
import store from '@/store'
import { ApiError } from '@/error-handler/types'
import { TOKEN_EXPIRED } from '@/store/auth/getter-types'

export const showErrors = (errors: Array<ApiError>, timeout = 3600) => {
    showSnackbar({
        mode: 'error',
        messages: errors.map(e => e.messages).flat(),
        timeout: timeout
    })
}

export const showErrorsIfExpired = (errors: Array<ApiError>, timeout = 3600) => {
    if (store.getters[namespacer('auth', TOKEN_EXPIRED)]()) {
        showErrors(errors, timeout)
    }
}

const showSnackbar = (content: SnackbarOptions) => {
    store.commit(namespacer('gui', SHOW_SNACKBAR), content)
}
