<template>
  <div
      class="sdgs"
      v-if="show"
  >
    <div>
      <p class="d-inline">{{ $t('components.microtrend.overlay.sdgs') }}</p>
    </div>
    <p>
      <template
          v-for="sdg in microtrend.sdgs"
      >
        <tm-tooltip :key="sdg.id" :text="sdg.translations[0].description">
          <trend-tag
              v-if="microtrend.innovationTypes"
              :node="sdg"
          />
        </tm-tooltip>
      </template>
    </p>
  </div>
</template>

<script>
import { Component, Prop, Vue } from 'vue-property-decorator'
import { MICROTRENDS } from '@/model/types'

import TrendTag from '@/components/trend-tag'
import TmTooltip from '@/ui/tm-tooltip/tm-tooltip.vue'

@Component({
    components: {
        TmTooltip,
        TrendTag
    }
})
export default class SdgsView extends Vue {
  @Prop({
      type: Object,
      validator: obj => MICROTRENDS.includes(obj.__typename),
      required: true
  }) microtrend

  get show () {
      return this.microtrend.sdgs && this.microtrend.sdgs.length > 0
  }
}
</script>
