import {
    COOKIE_CONSENT,
    MATOMO,
    TRACKING_CONSENT
} from '@/model/flags'

export const init = () => ({
    [TRACKING_CONSENT]: { [MATOMO]: true },
    [COOKIE_CONSENT]: { [MATOMO]: false }
})
export const state = init()
