<template>
  <v-dialog
    v-model="show"
    max-width="650"
  >
    <propose-innovation-project-form
      v-model="innovationProjectProposal"
      :pending="pending"
      @submit="onSubmit"
      @close="onClose"
    />
  </v-dialog>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import ProposeInnovationProjectForm from './form'

import proposeGQL from '@/graphql/app/innovation-project/mutation/propose.gql'
import { HIDE_PROPOSE_INNOVATION_PROJECT, SHOW_SNACKBAR } from '@/store/gui/mutation-types'
import { SHOW_INNOVATION_PROJECT_DIALOG } from '@/store/gui/getter-types'

const initProposal = () => ({
    title: null,
    description: null
})

const guiStore = namespace('gui')

@Component({
    components: {
        ProposeInnovationProjectForm
    }
})
export default class InnovastionProjectPropose extends Vue {
  @guiStore.Getter(SHOW_INNOVATION_PROJECT_DIALOG) showDialog
  @guiStore.Mutation(HIDE_PROPOSE_INNOVATION_PROJECT) hideDialog
  @guiStore.Mutation(SHOW_SNACKBAR) showSnackbar

  pending = false

  innovationProjectProposal = initProposal()

  get show () {
      return this.showDialog
  }

  set show (_) {
      this.onClose()
  }

  onClose () {
      this.innovationProjectProposal = initProposal()
      this.hideDialog()
  }

  async onSubmit () {
      try {
          this.pending = true
          await this.$apollo.mutate({
              mutation: proposeGQL,
              variables: {
                  input: this.innovationProjectProposal
              }
          })
          this.showSnackbar(this.$t('components.innovationProject.propose.confirmation'))
      } catch (error) {
      // eslint-disable-next-line no-console
          console.log('%cError on propose innovation-project:', 'color: red;', error)
      } finally {
          this.pending = false
      }
  }
}
</script>
