export const initScrapedInformation = () => ({
    url: null,
    locale: null,
    title: null,
    description: null,
    image: null
})

export const init = () => ({
    scraper: false,
    scrapedInformation: initScrapedInformation()
})

const state = init()
export default state
