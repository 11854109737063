
import { Component, Prop, VModel, Vue } from 'vue-property-decorator'
import { User } from '@/graphql/app/types'
import Avatar from '@/components/person/avatar.vue'
import UserSelectionMultiple from '@/components/form/user-select/user-selection-multiple.vue'
import UserSelectionSingle from '@/components/form/user-select/user-selection-single.vue'

@Component({
    components: {
        Avatar,
        UserSelectionMultiple,
        UserSelectionSingle
    }
})
export default class UserSelectUi extends Vue {
  @VModel({ default: () => [] }) selection!: Array<string> | string
  @Prop({ default: () => false }) multiple!: boolean
  @Prop({ default: () => false }) clearable!: boolean
  @Prop({ default: () => false }) loading!: boolean
  @Prop({ default: () => [] }) users!: Array<User>
  @Prop({ default: () => '' }) label!: string
  @Prop({ default: () => '' }) hint!: string
  @Prop({ default: () => true }) persistentHint!: boolean
  @Prop({ default: () => '' }) placeholder!: string

  get items (): Array<Record<string, any>> {
      return this.users.map((elem: User) => ({
          text: `${elem.firstName} ${elem.lastName}`,
          name: `${elem.firstName} ${elem.lastName}`,
          avatar: elem.avatar,
          email: elem.email,
          value: elem.id,
          id: elem.id,
          header: null
      }))
  }

  t (token: string, props: Record<string, any> = {}, cnt: number | null = null): string {
      const prefix = 'components.form.userSelect'
      return cnt === null
          ? this.$t(`${prefix}.${token}`, props).toString()
          : this.$tc(`${prefix}.${token}`, cnt, props).toString()
  }
}
