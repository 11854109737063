import { Component, Vue } from 'vue-property-decorator'

@Component
export default class FullscreenModalMixin extends Vue {
    addOverflowHidden () {
        if (!document.body.classList.contains('overflow-hidden')) {
            document.body.classList.add('overflow-hidden')
        }
    }

    removeOverflowHidden () {
        document.body.classList.remove('overflow-hidden')
    }
}
