<template>
  <form @submit.prevent="onSubmit">
    <v-card
      tile
    >
      <v-card-title>
        <div>
          <h3>{{ $t('components.innovationProject.proposalForm.title') }}</h3>
          <div class="text-caption">{{ $t('components.innovationProject.proposalForm.subHeadline') }}</div>
        </div>
      </v-card-title>
      <v-card-text>
        <v-text-field
          autofocus
          :value="value.title"
          :error-messages="titleErrors"
          @input="onInput({ name: 'title', value: $event })"
          :label="$t('components.innovationProject.proposalForm.form.title')"
          filled
        />
      </v-card-text>
      <v-card-text>
        <v-text-field
          multi-line
          no-resize
          :value="value.description"
          :error-messages="descriptionErrors"
          @input="onInput({ name: 'description', value: $event })"
          :label="$t('components.innovationProject.proposalForm.form.description')"
          filled
        />
      </v-card-text>
      <v-card-actions>
        <v-spacer />

        <v-btn
          text
          depressed
          color="primary"
          :disabled="pending"
          @click.stop="close">
          {{ $t('components.innovationProject.proposalForm.buttons.abort') }}
        </v-btn>

        <v-btn
          depressed
          type="submit"
          color="primary"
          :loading="pending"
          :disabled="hasErrors">
          {{ $t('components.innovationProject.proposalForm.buttons.submit') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </form>
</template>

<script>
import { Component, Prop, Vue } from 'vue-property-decorator'
import { required } from 'vuelidate/lib/validators'

const initProposal = () => ({
    title: null,
    description: null
})

@Component({
    validations: {
        value: {
            title: {
                required
            },
            description: {
                required
            }
        }
    }
})
export default class InnovationprojectProposeForm extends Vue {
  @Prop({
      type: Object,
      default: initProposal()
  }) value

  @Prop({
      type: Boolean,
      default: () => false,
      required: true
  }) pending

  get hasErrors () {
      return this.$v.$invalid
  }

  get titleErrors () {
      const errors = []

      if (!this.$v.value.title.$dirty) {
          return errors
      }

      !this.$v.value.title.required && errors.push(
          this.$t('components.innovationProject.proposalForm.validation.title')
      )

      return errors
  }

  get descriptionErrors () {
      const errors = []
      if (!this.$v.value.description.$dirty) {
          return errors
      }
      !this.$v.value.description.required && errors.push(
          this.$t('components.innovationProject.proposalForm.validation.description')
      )

      return errors
  }

  onInput (event) {
      if (event.name in this.$v.value) {
          this.$v.value[event.name].$touch()
      }

      this.$emit('input', Object.assign(
          initProposal(),
          this.value,
          {
              [event.name]: event.value
          }
      ))
  }

  close () {
      this.$emit('close')
      this.$v.$reset()
  }

  onSubmit () {
      this.$emit('submit')
      this.close()
  }
}
</script>
