import { loadLanguageAsync } from '@/plugins/i18n'
import * as authService from '@/service/auth'
import * as ACTION from './action-types'
import { CHANGE_LOCALE, INIT_2FA, LOGIN, LOGIN_FAILURE, LOGIN_SUCCESS, LOGOUT } from './mutation-types'

import dayjs from 'dayjs'
import {
    resetTrackingId,
    setCompanyForTracking,
    setModuleForTracking,
    setAccountTypeForTracking,
    setTrackingId,
    track
} from '@/plugins/tracker'

const DEFAULT_TTL_IN_SECONDS = 60 * 60

export default {
    LOGIN: async ({ commit, state }, loginData) => {
        if (loginData.redirectHash) {
            commit(INIT_2FA, loginData.redirectHash)
        } else {
            const data = {
                user: loginData.user,
                rules: authService.updateRules(loginData.user),
                tokenExpirationDate: dayjs().add(DEFAULT_TTL_IN_SECONDS, 's'),
                trackingId: loginData.trackingId
            }

            if (loginData.ttl) {
                data.tokenExpirationDate = dayjs().add(loginData.ttl, 's')
            }

            setCompanyForTracking(loginData.user.company.title)
            setAccountTypeForTracking(loginData.user.company.accountType)
            setModuleForTracking(loginData.user.company.product || '')
            setTrackingId(loginData.trackingId)
            track('User', 'login', 'success')

            if (state.locale !== data.user.locale) {
                await loadLanguageAsync(data.user.locale)
                commit(CHANGE_LOCALE, data.user.locale)
            }

            commit(LOGIN_SUCCESS, data)
        }
    },
    [ACTION.AZURE_LOGIN]: async ({
        commit,
        state,
        dispatch
    }, code) => {
        try {
            commit(LOGIN)
            await dispatch('LOGIN', await authService.azureLogin(code))
        } catch (error) {
            track('User', 'login', 'failure')
            commit(LOGIN_FAILURE)
            throw error
        }
    },

    [ACTION.CREDENTIALS_LOGIN]: async ({
        commit,
        state,
        dispatch
    }, credentials) => {
        try {
            commit(LOGIN)
            await dispatch('LOGIN', await authService.login(credentials))
        } catch (error) {
            track('User', 'login', 'failure')
            commit(LOGIN_FAILURE)
            throw error
        }
    },

    [ACTION.SECOND_FACTOR_LOGIN]: async ({
        commit,
        state,
        dispatch
    }, secondFactorData) => {
        try {
            commit(LOGIN)
            await dispatch('LOGIN', await authService.secondFactorLogin(secondFactorData))
        } catch (error) {
            track('User', 'login', 'failure')
            commit(LOGIN_FAILURE)
            throw error
        }
    },

    [ACTION.TRIAL_OPT_IN]: async ({ commit, state, dispatch }, payload) => {
        try {
            commit(LOGIN)
            await dispatch('LOGIN', await authService.activateTrial(payload))
        } catch (error) {
            track('User', 'login', 'failure')
            commit(LOGIN_FAILURE)
            throw error
        }
    },

    [ACTION.RESET]: async ({
        commit,
        state,
        dispatch
    }, payload) => {
        try {
            commit(LOGIN)
            await dispatch('LOGIN', await authService.reset(payload.token, payload.passwd))
        } catch (e) {
            track('User', 'login', 'failure')
            commit(LOGIN_FAILURE)
        }
    },

    [ACTION.LOGOUT]: async ({ commit }, locally = false) => {
        track('User', 'logout')
        setCompanyForTracking('')
        resetTrackingId()
        await authService.logout(locally)
        commit(LOGOUT)
    },

    [ACTION.CHANGE_LOCALE]: async ({ commit }, payload) => {
        track('User', 'changeLocale', payload)

        await loadLanguageAsync(payload)
        commit(CHANGE_LOCALE, payload)
    },

    [ACTION.UPDATE_RULES]: async ({ commit }) => {
        const me = await authService.fetchMe()
        const data = {
            user: me,
            rules: authService.updateRules(me)
        }
        commit(LOGIN_SUCCESS, data)
    },

    [ACTION.IMPERSONATION]: async ({ commit, dispatch }) => {
        try {
            commit(LOGIN)
            const me = await authService.fetchMe()
            const data = { user: me }
            await dispatch('LOGIN', data)
        } catch (error) {
            track('User', 'login', 'failure')
            commit(LOGIN_FAILURE)
            throw error
        }
    }
}
