
import { Component, Prop, Vue } from 'vue-property-decorator'
import Avatar from '@/components/person/avatar.vue'

@Component({
    components: { Avatar }
})
export default class UserSelectionSingle extends Vue {
  @Prop({ type: Object, required: true }) data!: object
}
