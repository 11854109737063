
import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import { HIDE_ADD_GENERIC, SHOW_SNACKBAR } from '@/store/gui/mutation-types'
import { GET_LOCALE } from '@/store/auth/getter-types'
import { GET_ADD_TREND_TO_INNOVATION_FIELD } from '@/store/gui/getter-types'

import LoadingCircle from '@/components/loading-circle/loading-circle.vue'
import ToIcon from '@/components/to-icon/to-icon.vue'
import { AddToInnovationFieldTrend, HideAddGeneric, ShowSnackbar } from '@/store/gui/types'
import {
    AddMacrotrendQueryQuery,
    AddNodesToInnovationFieldsMutation,
    addNodesToInnovationFieldsMutation,
    Locale,
    MacrotrendInterface,
    useAddMacrotrendQueryQuery
} from '@/graphql/app/types'
import { RESET_BULK_SELECTION, ResetBulkSelection } from '@/store/bulk-selection/mutations'

const authStore = namespace('auth')
const guiStore = namespace('gui')
const bulkSelectionStore = namespace('bulkSelection')

@Component<InnovationFieldAddTrend>({
    apollo: {
        add: useAddMacrotrendQueryQuery<InnovationFieldAddTrend>({
            // @ts-ignore
            variables () {
                return {
                    id: this.firstTrend.id,
                    locale: this.locale
                }
            },
            skip () {
                return this.firstTrend.id === null
            },
            update (data) {
                data?.innovationFields?.nodes.forEach(inf => {
                    this.used[inf.id] = []
                    inf.macrotrends.forEach(trend => {
                        this.used[inf.id].push(trend.id)
                    })
                })
                return data
            }
        })
    },
    components: {
        LoadingCircle,
        ToIcon
    }
})
export default class InnovationFieldAddTrend extends Vue {
    add: AddMacrotrendQueryQuery | null = null
    pending = false
    selected = []
    used: Record<string, Array<string>> = {}

  @authStore.Getter(GET_LOCALE) locale!: Locale

  @guiStore.Getter(GET_ADD_TREND_TO_INNOVATION_FIELD) trends!: Array<AddToInnovationFieldTrend>

  @guiStore.Mutation(SHOW_SNACKBAR) showSnackbar!: ShowSnackbar

  @guiStore.Mutation(HIDE_ADD_GENERIC) hideAddGeneric!: HideAddGeneric
  @bulkSelectionStore.Mutation(RESET_BULK_SELECTION) resetBulkSelection!: ResetBulkSelection

  get disabled () {
      return this.pending || this.selected.length === 0
  }

  get styles () {
      return {
          'background-color': this.trendInfo?.color || 'grey',
          color: 'white'
      }
  }

  get trendCount () {
      return this.trends.length
  }

  get show () {
      return this.firstTrend.id !== null && this.add !== null && this.firstTrend.type !== null
  }

  get firstTrend () {
      return this.trends[0]
  }

  set show (_) {
      this.hide()
  }

  get trendInfo () {
      if (this.add !== null) {
          const macrotrend = this.add.macrotrend as MacrotrendInterface
          const translation = macrotrend && macrotrend.translations && macrotrend.translations[0]

          return {
              title: translation && translation.title ? translation.title : 'n/a',
              color: macrotrend && macrotrend.megatrend ? macrotrend.megatrend.color : 'grey'
          }
      }

      return null
  }

  hide () {
      this.hideAddGeneric()
      this.add = null
      this.pending = false
      this.selected = []
  }

  handleSuccess (data: AddNodesToInnovationFieldsMutation) {
      this.$track('InnovationField', 'add', 'Macrotrend')
      this.showSnackbar({
          mode: 'feedback',
          messages: this.successMessages(),
          targets: data.addNodesToInnovationFields.map((elem: { id: string; title: string }) => ({
              to: {
                  name: 'innovation-field-detail',
                  params: { innovationFieldId: elem.id }
              },
              title: elem.title
          }))
      })
      this.resetBulkSelection()
      this.hide()
  }

  private successMessages (): Array<string> {
      const message = this.trendCount === 1
          ? this.$t('components.innovationField.addTrend.success.single', { title: this.trendInfo?.title || 'n/a' })
          : this.$t('components.innovationField.addTrend.success.bulk')

      return [message as string]
  }

  async onSubmit () {
      try {
          this.pending = true
          const result = await addNodesToInnovationFieldsMutation(this, {
              variables: {
                  input: {
                      nodeIds: this.trends.map(trend => trend.id).filter(id => id !== null) as Array<string>,
                      innovationFieldIds: this.selected
                  },
                  locale: this.locale
              }
          })
          if (result.data) {
              this.handleSuccess(result.data)
          }
      } catch (error) {
          console.log('%cError on add trend to innovation-field: ', 'color:red', error) // eslint-disable-line no-console
      } finally {
          this.pending = false
      }
  }

  alreadyInUse (innovationFieldId: string): boolean {
      if (this.trends.length > 1) {
          return false
      }

      const trendId = this.firstTrend.id
      if (trendId === null) {
          return false
      }

      return (
          Array.isArray(this.used[innovationFieldId]) && this.used[innovationFieldId].includes(trendId)
      )
  }
}
