
import { Component, Prop, Vue } from 'vue-property-decorator'

import MacrotrendElement from './macrotrend-element.vue'
import MegatrendElement from './megatrend-element.vue'
import TrendTreeLoader from '@/components/trend-tree/trend-tree-loader.vue'
import { MacrotrendInterface } from '@/graphql/app/types'
import Megatrend from '@/model/trends/megatrend'
import Macrotrend from '@/model/trends/macrotrend'
import Container from '@/model/trends/container'
import TrendTag from '@/components/trend-tag/index.vue'

@Component({
    components: {
        TrendTreeLoader,
        MacrotrendElement,
        MegatrendElement,
        TrendTag
    }
})
export default class TrendTreeUi extends Vue {
  @Prop({
      default: () => []
  }) value!: Array<Macrotrend | MacrotrendInterface>

  @Prop({
      required: true
  }) trendtree!: Container

  @Prop({
      default: () => false
  }) loading!: boolean

  @Prop({
      default: () => false
  }) addOnly!: boolean

  @Prop({
      default: () => false
  }) showTags!: boolean

  private search: string | null = null
  private show: boolean = false
  private editable: Record<string, boolean> = {}

  get searched (): Record<string, Megatrend> {
      return this.trendtree.search(this.search || '')
  }

  sort (hashmap: Record<string, Megatrend | Macrotrend>) {
      return Object.values(hashmap).sort((a, b) => a.title.localeCompare(b.title))
  }

  onSearch (value: string | null): void {
      this.search = value
      if (this.search && !this.show) {
          this.show = true
      }
  }

  isMegatrendSelected (megatrend: Megatrend): boolean | null {
      const self = this
      const macrotrends = Object.values(megatrend.macrotrends)

      let cnt = 0
      macrotrends.forEach(elem => {
          cnt += self.isMacrotrendSelected(elem) ? 1 : 0
      })

      if (cnt === 0) {
          return false
      }

      if (cnt === macrotrends.length) {
          return true
      }

      return null
  }

  addOnlyState (macrotrend: Macrotrend): boolean {
      return this.addOnly
          ? this.isMacrotrendSelected(macrotrend) && !this.inEditable(macrotrend.id)
          : false
  }

  isMacrotrendSelected (macrotrend: Macrotrend): boolean {
      return this.value.find(elem => elem.id === macrotrend.id) !== undefined
  }

  onAddMegatrend (megatrend: Megatrend): void {
      const add = Object.values(megatrend.macrotrends)
      this.$emit('input', [...new Set(this.value.concat(add))])
  }

  onRemoveMegatrend (megatrend: Megatrend): void {
      const ids = Object.keys(megatrend.macrotrends)
      const emit = this.value.filter(elem => !ids.includes(elem.id))
      this.$emit('input', emit)
  }

  onAddMacrotrend (macrotrend: Macrotrend): void {
      const emit = [...new Set(this.value.concat([macrotrend]))]
      this.$emit('input', emit)
      this.addToEditable(macrotrend.id)
  }

  onRemoveMacrotrend (macrotrend: Macrotrend): void {
      this.$emit('input', this.value.filter(elem => elem.id !== macrotrend.id))
      this.removeFromEditable(macrotrend.id)
  }

  visible (megatrend: Megatrend): boolean {
      return megatrend.visible && Object.keys(megatrend.macrotrends).length > 0
  }

  addToEditable (id: string): void {
      this.editable[id] = true
  }

  removeFromEditable (id: string): void {
      delete this.editable[id]
  }

  inEditable (id: string): boolean {
      return id in this.editable
  }
}
