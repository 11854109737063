<template>
  <v-list-item
    dense
    two-line
  >
    <v-list-item-action>
      <v-checkbox
        :key="macrotrend.id"
        :color="color"
        v-model="checked"
        @click.stop.prevent="checked = !checked"
        :disabled="disabled"
        :aria-labelledby="id"
      />
    </v-list-item-action>
    <v-list-item-content>
      <v-list-item-title :id="id">
        {{ macrotrend.title }}
      </v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component()
export default class TrendTreeMacrotrendElement extends Vue {
  @Prop({
      type: Boolean,
      default: () => false
  }) selected

  @Prop({
      type: Boolean,
      default: () => false
  }) disabled

  @Prop({
      type: Object,
      required: true
  }) macrotrend

  set checked (value) {
      return value
          ? this.$emit('add', this.macrotrend)
          : this.$emit('remove', this.macrotrend)
  }

  get checked () {
      return this.selected !== false
  }

  get color () {
      return this.disabled ? null : 'primary'
  }

  get id () {
      return `trend-tree-macrotrend-${this.macrotrend.id}`
  }
}
</script>
