import { Component, Vue } from 'vue-property-decorator'
import { Locale, Maybe } from '@/graphql/app/types'
import 'dayjs/locale/de'
import 'dayjs/locale/en'
import minMax from 'dayjs/plugin/minMax'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import dayjs from 'dayjs'

dayjs.extend(minMax)
dayjs.extend(localizedFormat)

@Component
export default class DateMixin extends Vue {
    date (v: Maybe<string>): string {
        return v ? dayjs(v).locale(this._locale).format('ll') : ''
    }

    datetime (v: Maybe<string>): string {
        return v ? dayjs(v).locale(this._locale).format('ll, HH:mm') : ''
    }

    now (): string {
        return dayjs().locale(this._locale).format('dddd, ll')
    }

    unix (v: Maybe<string>): string {
        return dayjs(v).unix().toString()
    }

    private get _locale (): string {
        return this.$i18n && this.$i18n.locale
            ? this.$i18n.locale
            : Locale.De
    }

    formatStringDayMonth (includeYear = false): string {
        return this._locale === Locale.De
            ? includeYear ? 'll' : 'D. MMM'
            : includeYear ? 'll' : 'MMM D'
    }

    range (from: string|undefined, to: string|undefined): string {
        const formatted: Array<Maybe<string>> = new Array(2).fill(null)
        const f = dayjs(from)
        const t = dayjs(to)

        if (from && to) {
            const start = dayjs.min(f, t)
            const end = dayjs.max(f, t)

            formatted[1] = end.isSame(start, 'day')
                ? null
                : end.locale(this._locale).format('ll')

            formatted[0] = start.isSame(end, 'year')
                ? start.locale(this._locale).format(this.formatStringDayMonth(formatted[1] === null))
                : start.locale(this._locale).format('ll')

            formatted[1] = end.isSame(start, 'day')
                ? null
                : end.locale(this._locale).format('ll')
        } else {
            if (from) {
                formatted[0] = f.locale(this._locale).format('ll')
            }
            if (to) {
                formatted[1] = t.locale(this._locale).format('ll')
            }
        }

        return formatted.filter(elem => elem !== null).join(' - ')
    }
}
