<template>
  <div
    v-if="show"
    class="macrotrends"
  >
    <div>
      <p class="d-inline">
        {{ $t('components.microtrend.overlay.macrotrends') }}
      </p>
      <v-btn
        v-if="$can('update', microtrend)"
        class="ma-0 float-right"
        color="primary"
        icon
        rounded
        small
        text
        @click="onClick"
      >
        <to-icon>edit</to-icon>
      </v-btn>
    </div>
    <p>
      <template
        v-for="macrotrend in macrotrends"
      >
        <trend-tag
          :key="macrotrend.id"
          :node="macrotrend"
          :to="{ name: 'macrotrend-detail', params: { macrotrendId: macrotrend.id }}"
        />
      </template>
    </p>
  </div>
</template>

<script>
import { Component, Prop, Vue } from 'vue-property-decorator'
import { MICROTRENDS } from '@/model/types'
import { EDIT } from '@/model/views/modes'

import ToIcon from '@/components/to-icon/to-icon'
import TrendTag from '@/components/trend-tag'

@Component({
    components: {
        ToIcon,
        TrendTag
    }
})
export default class MacrotrendsView extends Vue {
  @Prop({
      type: Object,
      validator: obj => MICROTRENDS.includes(obj.__typename),
      required: true
  }) microtrend

  get macrotrends () {
      return this.microtrend
          ? this.microtrend.macrotrends
          : []
  }

  get show () {
      return this.macrotrends.length > 0 || this.$can('update', this.microtrend)
  }

  onClick () {
      this.$emit('change-mode', EDIT)
  }
}
</script>
