import participate from './participate'
import view from './view'

export default {
    namespaced: true,
    getters: {},
    modules: {
        participate,
        view
    }
}
