import Megatrend from './megatrend'
import Macrotrend from './macrotrend'
import { MacrotrendSearchFacet, MegatrendSearchFacet } from '@/graphql/app/types'

export default class Container {
    private megatrends: Record<string, Megatrend>

    constructor () {
        this.megatrends = {}
    }

    addMegatrend (megatrend: Megatrend): void {
        this.megatrends[megatrend.id] = megatrend
    }

    hasMegatrend (id: string): boolean {
        return id in this.megatrends
    }

    getMegatrend (id: string): Megatrend | null {
        return this.hasMegatrend(id) ? this.megatrends[id] : null
    }

    reset (): void {
        const self = this
        Object.keys(self.megatrends).map(k1 => {
            self.megatrends[k1].open = false
            self.megatrends[k1].visible = true
            Object.keys(self.megatrends[k1].macrotrends).map(k2 => {
                self.megatrends[k1].macrotrends[k2].visible = true
            })
        })
    }

    search (token: string): Record<string, Megatrend> {
        this.reset()

        if (!token || token.length === 0) {
            return this.megatrends
        }

        const re = new RegExp(token, 'i')
        const self = this

        Object.keys(this.megatrends).map(k1 => {
            self.megatrends[k1].visible = self.megatrends[k1].title.search(re) > -1

            Object.keys(self.megatrends[k1].macrotrends).map(k2 => {
                if (self.megatrends[k1].macrotrends[k2].title.search(re) > -1) {
                    self.megatrends[k1].macrotrends[k2].visible = true
                    self.megatrends[k1].visible = true
                    self.megatrends[k1].open = true
                } else {
                    self.megatrends[k1].macrotrends[k2].visible = false
                }
            })
        })

        return this.megatrends
    }

    static create (data: { megatrends: Array<MegatrendSearchFacet>, macrotrends: Array<MacrotrendSearchFacet> }): Container {
        const container = new Container()

        for (const element of data.megatrends) {
            container.addMegatrend(Megatrend.fromMegatrendInterface(element.node))
        }

        for (const element of data.macrotrends) {
            const megatrendId = element.node.megatrend?.id || null
            if (megatrendId === null) {
                continue
            }

            const megatrend = container.getMegatrend(megatrendId)
            if (megatrend === null) {
                continue
            }

            megatrend.addMacrotrend(Macrotrend.fromMacrotrendInterface(element.node))
        }

        return container
    }
}
