<template>
  <div>
    <innovationfield-add-trend/>
    <innovationfield-delete/>
    <innovationproject-propose/>
    <microtrend-overlay/>
    <microtrend-pin-to-macrotrend/>
    <microtrend-scraper/>
    <new-features/>
    <export-dialog/>
    <search-agent-add/>
    <search-agent-delete/>
    <search-agent-edit/>
    <universe-updates/>
    <workspace-add-trend/>
    <workspace-delete/>
    <workspace-copy/>
    <workspace-edit/>
    <workspace-leave/>
    <feedback-dialog/>
  </div>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator'

import GlobalExportGlobalDialog from '@/components/export/global-dialog.vue'
import InnovationfieldAddTrend from '@/components/innovation-field/add/add-trend'
import InnovationfieldDelete from '@/components/innovation-field/delete/delete'
import InnovationprojectPropose from '@/components/innovation-project/propose/propose'
import MicrotrendPinToMacrotrend from '@/components/microtrend/pin/to-macro'
import MicrotrendScraper from '@/components/company-microtrend/scraper/scraper'
import MicrotrendOverlay from '@/components/microtrend/overlay/overlay'
import NewFeatures from '@/components/new-features'
import SearchAgentAdd from '@/components/search-agent/add/add'
import SearchAgentDelete from '@/components/search-agent/delete/delete'
import SearchAgentEdit from '@/components/search-agent/edit/edit'
import WorkspaceAddTrend from '@/components/workspace/add/add-trend'
import WorkspaceDelete from '@/components/workspace/delete/delete'
import WorkspaceEdit from '@/components/workspace/edit/edit'
import WorkspaceLeave from '@/components/workspace/leave/leave'
import WorkspaceCopy from '@/components/workspace/copy/copy'
import UniverseUpdates from '@/components/universe-updates'
import FeedbackDialog from '@/components/tx-migration/dialog/feedback'

@Component({
    components: {
        ExportDialog: GlobalExportGlobalDialog,
        FeedbackDialog,
        InnovationfieldAddTrend,
        InnovationfieldDelete,
        InnovationprojectPropose,
        MicrotrendOverlay,
        MicrotrendPinToMacrotrend,
        MicrotrendScraper,
        NewFeatures,
        SearchAgentAdd,
        SearchAgentDelete,
        SearchAgentEdit,
        UniverseUpdates,
        WorkspaceAddTrend,
        WorkspaceDelete,
        WorkspaceEdit,
        WorkspaceLeave,
        WorkspaceCopy
    }
})
export default class GlobalDialogs extends Vue {
}
</script>
