
import { Component, Prop, VModel, Vue } from 'vue-property-decorator'
import { CriteriaSet } from '@/graphql/app/types'

@Component
export default class CriteriaSetSelection extends Vue {
  @VModel() selection!: string
  @Prop({ default: () => false }) loading!: boolean
  @Prop({ default: () => [] }) criteriaSets!: Array<CriteriaSet>
  @Prop({ default: () => [] }) errorMessages!: Array<string>
  @Prop({ default: () => false }) readonly!: boolean
  @Prop({ default: () => false }) disabled!: boolean

  get items (): Array<{ text: string, value: string }> {
      return this.criteriaSets.map((e: CriteriaSet) => ({
          value: e.id,
          text: e.translations[0].title
      }))
  }

  t (token: string): string {
      const prefix = 'components.criteriaSet'
      return this.$t(`${prefix}.${token}`).toString()
  }
}
