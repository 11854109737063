
import { Component, Prop, VModel, Vue } from 'vue-property-decorator'

import { namespace } from 'vuex-class'
import { GET_USER_ID } from '@/store/auth/getter-types'
import UserSelectLoader from '@/components/form/user-select/user-select-loader.vue'
import UserSelectUi from '@/components/form/user-select/user-select-ui.vue'

const authStore = namespace('auth')

@Component({
    components: { UserSelectUi, UserSelectLoader }
})
export default class UserSelect extends Vue {
  @VModel() selection!: Array<string> | string

  @Prop({ default: () => false }) avoidMe!: boolean
  @Prop({ default: () => '' }) label!: string
  @Prop({ default: () => '' }) hint!: string
  @Prop({ default: () => true }) persistentHint!: boolean
  @Prop({ default: () => '' }) placeholder!: string
  @Prop({ default: () => false }) multiple!: boolean
  @Prop({ default: () => false }) clearable!: boolean

  @authStore.Getter(GET_USER_ID) userId!: string

  get avoidMeId (): string {
      return this.avoidMe
          ? this.userId || ''
          : ''
  }
}
