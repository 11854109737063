import i18n from '@/plugins/i18n'

export const translateCodeToMessage = (code: number, path: string): string => {
    const messageKey = `${path}.${code}`
    const errorMessage = i18n.t(messageKey).toString()

    if (errorMessage === messageKey) {
        return i18n.t(`${path}.noMatch`).toString()
    }

    return errorMessage
}
