<template>
  <form
    @submit.prevent="onSubmit"
  >
    <v-card
      tile
    >
      <v-card-title>
        <h3>{{ $t('components.searchAgent.form.type') }}</h3>
      </v-card-title>
      <v-card-text>
        <v-text-field
          name="title"
          v-model="$v.internal.title.$model"
          :label="$t('components.searchAgent.form.title.label')"
          :error-messages="titleErrors"
          filled
        />
      </v-card-text>

      <v-card-text
        v-if="workspace && workspace.title"
        class="text-caption pb-0"
      >
        {{ $t('components.searchAgent.form.info') }}: {{ workspace.title }}
      </v-card-text>

      <v-card-text>
        <search-criteria
          v-model="$v.internal.search.$model"
          :locale="locale"
          :validation="$v.internal.search"
          :ad-hoc="true"
        />
      </v-card-text>

      <v-card-actions>
        <v-spacer/>
        <v-btn
          small
          depressed
          text
          color="primary"
          @click="onClose"
          :disabled="pending"
        >
          <b>{{ $t('components.searchAgent.form.button.abort') }}</b>
        </v-btn>

        <v-btn
          depressed
          @click="onSubmit"
          color="primary"
          :loading="pending"
          :disabled="$v.internal.$invalid || pending"
        >
          {{ $t('components.searchAgent.form.button.submit') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </form>
</template>

<script>
import { Component, Prop, Vue } from 'vue-property-decorator'
import { required } from 'vuelidate/lib/validators'
import { empty } from '@/model/search-agent/initial'

import SearchCriteria from '@/components/search-agent/edit/search'

@Component({
    components: {
        SearchCriteria
    },
    validations: {
        internal: {
            title: {
                required
            },
            search: {
                searchValidator (obj) {
                    if (!obj) {
                        return false
                    }

                    if (('query' in obj) && obj.query !== null && obj.query.length > 0) {
                        return true
                    }

                    return ('facetState' in obj) && Object.keys(obj.facetState).length > 0
                }
            }
        }
    }
})
export default class SearchAgentForm extends Vue {
  @Prop({
      type: Object,
      default: () => null
  }) workspace

  @Prop({
      type: Boolean,
      default: () => false
  }) pending

  @Prop({
      type: Object,
      default: () => empty()
  }) value

  errors = null

  get internal () {
      return this.value
  }

  set internal (value) {
      this.$emit('input', value)
  }

  get titleErrors () {
      const errors = []
      this.$v.internal.title.$dirty &&
    !this.$v.internal.title.required &&
    errors.push(this.$t('components.searchAgent.form.title.error'))
      return errors
  }

  onSubmit () {
      this.$emit('submit')
      this.$v.$reset()
  }

  onClose () {
      this.$emit('close')
      this.$v.$reset()
  }
}
</script>
