import { authentication } from '@/plugins/router/hooks/authentication'
import { enforceLocale } from '@/plugins/router/hooks/enforce-locale'
import { authorizationCheck } from '@/plugins/router/hooks/authorization-check'
import { microTrendOverlay } from '@/plugins/router/hooks/microtrend-overlay'
import { trendfinder } from '@/plugins/router/hooks/trendfinder'
import { testTrendfinder } from '@/plugins/router/hooks/test-trendfinder'
import { roleCheck } from '@/plugins/router/hooks/role-check'
import { multilang } from '@/plugins/router/hooks/multilang'
import { viewPagination } from '@/plugins/router/hooks/view-pagination'
import { updateAbilities } from '@/plugins/router/hooks/update-abilities'

export default [
    authentication,
    enforceLocale,
    authorizationCheck,
    updateAbilities,
    microTrendOverlay,
    trendfinder,
    testTrendfinder,
    roleCheck,
    multilang,
    viewPagination
]
