import { appClient } from '@/plugins/apollo'

import setInsightsSeenGQL from '@/graphql/app/user/mutation/set-insights-seen.gql'

export const setInsightsUpdatesSeen = async () => {
    const response = await appClient.mutate({
        mutation: setInsightsSeenGQL
    })
    return response.data.setInsightsSeen
}
