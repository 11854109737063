import * as ID from './ids'
import * as COL from './columns'
import { PAGE_SIZE } from '@/model/constants'

export const PAGINATION = {
    [ID.INSIGHTS_TRENDING_TOPICS]: {
        sortDesc: [true],
        field: 'date',
        rowsPerPage: PAGE_SIZE,
        sortBy: [COL.DATE]
    },
    [ID.INSIGHTS_TRENDCALLS]: {
        sortDesc: [true],
        field: 'date',
        rowsPerPage: PAGE_SIZE,
        sortBy: [COL.DATE]
    },
    [ID.INSIGHTS_TREND_SUMMARIES]: {
        sortDesc: [true],
        field: 'date',
        rowsPerPage: PAGE_SIZE,
        sortBy: [COL.DATE]
    },
    [ID.INNOVATION_FIELDS]: {
        sortDesc: [true],
        page: 1,
        rowsPerPage: PAGE_SIZE,
        sortBy: [COL.UPDATED_AT]
    },
    [ID.INNOVATION_PROJECTS]: {
        sortDesc: [true],
        page: 1,
        rowsPerPage: PAGE_SIZE,
        sortBy: [COL.UPDATED_AT]
    },
    [ID.TRENDFINDER_MACROTRENDS]: {
        sortDesc: [false],
        page: 1,
        rowsPerPage: PAGE_SIZE,
        sortBy: [COL.TITLE]
    },
    [ID.TRENDFINDER_MEGATRENDS]: {
        sortDesc: [false],
        page: 1,
        rowsPerPage: PAGE_SIZE,
        sortBy: [COL.TITLE]
    },
    [ID.TRENDFINDER_MICROTRENDS]: {
        sortDesc: [true],
        page: 1,
        rowsPerPage: PAGE_SIZE,
        sortBy: [COL.IDENTIFIED_AT]
    },
    [ID.TRENDRADAR_RADARS]: {
        sortDesc: [true],
        page: 1,
        rowsPerPage: PAGE_SIZE,
        sortBy: [COL.UPDATED_AT]
    },
    [ID.TRENDRADAR_PARTICIPATIONS]: {
        sortDesc: [true],
        page: 1,
        rowsPerPage: PAGE_SIZE,
        sortBy: [COL.UPDATED_AT]
    },
    [ID.WORKSPACES]: {
        sortDesc: [true],
        page: 1,
        rowsPerPage: PAGE_SIZE,
        sortBy: [COL.UPDATED_AT]
    }
}
