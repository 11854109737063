<template>
  <v-list-item-avatar
    v-if="list"
    :size="size"
    class="user-avatar"
  >
    <img
      :src="icon"
      :key="icon"
      :alt="alt"
      :title="title"
      ref="avatar"
      class="avatar-img"
    />
  </v-list-item-avatar>
  <v-avatar
    v-else
    :size="size"
  >
    <img
      :key="icon"
      :src="icon"
      :alt="alt"
      :title="title"
      ref="avatar"
      class="avatar-img"
    >
  </v-avatar>
</template>

<script>
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import objectFitImages from 'object-fit-images'

@Component()
export default class Avatar extends Vue {
  @Prop({
      type: String,
      validate: obj => typeof obj === 'string' && obj.length > 0
  }) icon

  @Prop({
      type: String,
      default: '48px'
  }) size

  @Prop({
      type: String,
      default: null
  }) title

  @Prop({
      type: Boolean,
      default: false
  }) list

  get alt () {
      return this.title
          ? 'Avatar: ' + this.title
          : null
  }

  mounted () {
      objectFitImages(this.$refs.avatar)
  }

  @Watch('icon')
  onChangeIcon () {
      objectFitImages(this.$refs.avatar)
  }
}
</script>
