<template>
  <div
    v-if="show"
    class="innovation-types"
  >
    <div>
      <p class="d-inline">{{ $t('components.microtrend.overlay.innovationTypes') }}</p>
      <v-btn
        v-if="$can('update', microtrend)"
        small
        rounded
        icon
        text
        color="primary"
        class="ma-0 float-right"
        @click="onClick"
      >
        <to-icon>edit</to-icon>
      </v-btn>
    </div>
    <p>
      <template
        v-for="innovationType in microtrend.innovationTypes"
      >
        <trend-tag
          v-if="microtrend.innovationTypes"
          :key="innovationType.id"
          :node="innovationType"
          :to="{ name: 'trendfinder', query: { f: innovationType.id }}"
        />
      </template>
    </p>
  </div>
</template>

<script>
import { Component, Prop, Vue } from 'vue-property-decorator'
import { MICROTRENDS } from '@/model/types'
import { EDIT } from '@/model/views/modes'

import TrendTag from '@/components/trend-tag'
import ToIcon from '@/components/to-icon/to-icon'

@Component({
    components: {
        TrendTag,
        ToIcon
    }
})
export default class InnovationTypesView extends Vue {
  @Prop({
      type: Object,
      validator: obj => MICROTRENDS.includes(obj.__typename),
      required: true
  }) microtrend

  get show () {
      return this.microtrend.innovationTypes || this.$can('update', this.microtrend)
  }

  onClick () {
      this.$emit('change-mode', EDIT)
  }
}
</script>
