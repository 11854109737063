import { DEFAULT_LOCALE } from '@/plugins/i18n'

export const browserLocale = (): string => window.navigator.language.split('-')[0] || DEFAULT_LOCALE
export const storageLocale = (): string => {
    try {
        const storage = (window as Window).localStorage
        const data = JSON.parse(storage.getItem('trendmanager') || '{}')
        return data.auth.locale || browserLocale()
    } catch (e) {
        return browserLocale()
    }
}
