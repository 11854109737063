import { appClient } from '@/plugins/apollo'

import updatePublicInnovationFieldsStateGQL
    from '@/graphql/app/innovation-field/mutation/update-public-innovation-field-state.gql'
import publicInnovationFieldsUrlGQL from '@/graphql/app/innovation-field/query/public-innovation-fields-url.gql'

export const updatePublicInnovationFieldsState = async (payload) => {
    const response = await appClient.mutate({
        mutation: updatePublicInnovationFieldsStateGQL,
        variables: {
            input: { active: payload }
        }
    })
    return response.data.updatePublicInnovationFieldsState
}

export const getPublicInnovationFieldsUrl = async () => {
    const response = await appClient.query({
        query: publicInnovationFieldsUrlGQL,
        fetchPolicy: 'no-cache'
    })
    return response.data.publicInnovationFieldsUrl
}
