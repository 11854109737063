import * as MUTATION from './mutation-types'

export default {
    [MUTATION.UPDATE_PARTICIPATION]: (state, payload) => {
        Object.keys(payload).forEach(key => {
            if (key in state.participation) {
                state.participation[key] = payload[key]
            }
        })
    },

    [MUTATION.SET_PENDING]: (state, payload) => {
        state.pending = payload
    },

    [MUTATION.SET_LOADING]: (state, payload) => {
        state.loading = payload
    },

    [MUTATION.SET_PARTICIPATION_COMPLETED]: (state, payload) => {
        state.participationCompleted = payload
    }
}
