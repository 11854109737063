import * as innovationFieldService from '@/service/innovation-fields'
import * as ACTION from '@/store/innovation-field/action-types'

export default {
    [ACTION.UPDATE_PUBLIC_INNOVATION_FIELDS_STATE]: async (state, payload) => {
        return await innovationFieldService.updatePublicInnovationFieldsState(payload)
    },

    [ACTION.GET_PUBLIC_INNOVATION_FIELDS_URL]: async (state) => {
        return await innovationFieldService.getPublicInnovationFieldsUrl()
    }
}
