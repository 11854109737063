
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class DeprecationTooltip extends Vue {
  @Prop({
      type: String,
      required: true
  }) text!: string

  @Prop({
      type: Boolean,
      default: false
  }) admin!: boolean

  @Prop({
      type: Boolean,
      default: false
  }) autoColor!: boolean

  @Prop({
      type: Boolean,
      default: false
  }) absolute!: boolean

  @Prop({
      type: Number,
      default: 0
  }) bottom!: number

  @Prop({
      type: Boolean,
      default: false
  }) small!: boolean

  get iconStyle () {
      if (this.small) {
          return {
              'font-size': '1em',
              top: '3px',
              left: '-4px'
          }
      }
      return {}
  }

  get style () {
      return {
          position: this.absolute ? 'absolute' : 'relative',
          visibility: 'visible',
          color: this.autoColor ? 'inherit !important' : 'red !important',
          bottom: this.absolute ? `${this.bottom}px` : '0px'
      }
  }
}
