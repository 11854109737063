import getters from '@/store/bulk-selection/getters'
import mutations from '@/store/bulk-selection/mutations'
import { state } from '@/store/bulk-selection/state'

export default {
    namespaced: true,
    getters,
    mutations,
    state
}
