import store from '@/store'
import { namespacer } from '@/util/namespacer'
import { GET_MICROTREND } from '@/store/gui/getter-types'
import { HIDE_MICROTREND_OVERLAY, SHOW_MICROTREND_OVERLAY } from '@/store/gui/action-types'

export const microTrendOverlay = (to, from, next) => {
    if (to.query.microtrendId) {
        store.dispatch(namespacer('gui', SHOW_MICROTREND_OVERLAY), to.query.microtrendId).then(() => {
            next()
        })
    } else {
        if (store.getters[namespacer('gui', GET_MICROTREND)]) {
            store.dispatch(namespacer('gui', HIDE_MICROTREND_OVERLAY)).then(() => {
                next()
            })
        } else {
            next()
        }
    }
}
