import { ComponentPublicInstance } from 'vue'

export const errorHandler = (err: any, vm: ComponentPublicInstance, info: string) => {
    if (process.env.NODE_ENV !== 'production') {
        console.group('%cCaught error (dev)', 'color: red;')
        console.log({ err, info })
        console.groupEnd()
    } else {
        console.log(`%cError (prod): ${err.message}`, 'color: red;')
    }
}
