import * as Getter from './getter-types'

export default {
    [Getter.GET_CURRENT_PAGE_INFO]: (state) => state.currentPageInfo,

    [Getter.GET_CURRENT_WORKSPACE]: (state) => state.currentWorkspace,

    [Getter.GET_CURRENT_SEARCH_AGENT]: (state) => state.currentSearchAgent,

    [Getter.SHOW_DELETE]: (state) => state.delete,

    [Getter.SHOW_EDIT]: (state) => state.edit
}
