import Vue from 'vue'
import Vuetify from 'vuetify'
import de from 'vuetify/lib/locale/de'
import '@mdi/font/css/materialdesignicons.css'
// import { themeOptions } from '@/plugins/theme-options'

Vue.use(Vuetify)

export const options = {
    theme: { disable: true },
    // theme: themeOptions,
    lang: {
        locales: { de },
        current: 'de'
    }
}

export default new Vuetify(options)
