
import { Locale, useTrendFacetsQuery } from '@/graphql/app/types'
import { namespace } from 'vuex-class'
import { Component, Vue } from 'vue-property-decorator'

import Container from '@/model/trends/container'
import { GET_LOCALE } from '@/store/auth/getter-types'

const auth = namespace('auth')

@Component<TrendTreeLoader>({
    apollo: {
        trendtree: useTrendFacetsQuery<TrendTreeLoader>({
            variables () {
                return {
                    locale: this.locale
                }
            },
            update (data) {
                if (data.trendtree && data.trendtree.microtrends && data.trendtree.microtrends.facets) {
                    return Container.create(data.trendtree.microtrends.facets)
                }

                return new Container()
            }
        })
    }
})
export default class TrendTreeLoader extends Vue {
  @auth.Getter(GET_LOCALE) locale!: Locale

  private trendtree: Container = new Container()

  get loading (): boolean {
      return this.$apollo.queries.trendtree.loading
  }
}
