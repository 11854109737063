<template>
  <v-navigation-drawer
    v-model="internal"
    :expand-on-hover="!mobile"
    :mini-variant-width="60"
    :permanent="!mobile"
    :temporary="mobile"
    :width="200"
    app
    class="px-0 mx-0"
    clipped
    dense
    disable-resize-watcher
    hide-overlay
  >
    <v-row
      class="pa-0 ma-0 flex-column flex-nowrap justify-space-between fill-height align-center"
      @mouseenter="mini = false"
      @mouseleave="mini = true"
    >
      <v-list
        class="main-nav-list justify-start"
        dense
      >
        <template
          v-if="!mobile"
        >
          <v-list-item
            class="no-hover logo-item"
          >
            <v-list-item-icon>
              <div class="logo-small"></div>
            </v-list-item-icon>
            <v-list-item-content
              class="logo-container"
            >
              <div class="logo"></div>
            </v-list-item-content>
          </v-list-item>
          <v-divider/>
        </template>

        <template
          v-if="mobile"
        >
          <v-list-item
            class="nav-item"
            @click="internal = false"
          >
            <v-list-item-icon>
              <v-icon>mdi-chevron-left</v-icon>
            </v-list-item-icon>
            <v-list-item-title>
              {{ $t('components.navigation.sidebar.close') }}
            </v-list-item-title>
          </v-list-item>

          <v-divider/>
        </template>

        <template v-if="showCreateNavigation">
          <create-navigation
            :mini="mini"
          />
          <v-divider class="mb-1"/>
        </template>

        <!-- Dashboard -->
        <v-list-item
          :to="{  name: 'dashboard' }"
          class="nav-item"
        >
          <v-list-item-icon>
            <to-icon size="20px">dashboard</to-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              {{ $t('components.navigation.sidebar.dashboard') }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <!-- TRENDFINDER -->
        <v-list-item
          :to="{ name: 'trendfinder' }"
          class="nav-item"
        >
          <v-list-item-icon>
            <to-icon size="20">finder</to-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              {{ $t('components.navigation.sidebar.trendfinder') }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <!-- WORKSPACE -->
        <v-list-item
          v-if="hasWorkspaceFeature"
          :to="{ name: 'workspace' }"
          class="nav-item"
          data-test-id="workspaces-nav-item"
        >
          <v-list-item-icon>
            <to-icon size="20px">rocket</to-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              {{ $t('components.navigation.sidebar.workspace') }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <!-- TRENDRADAR -->
        <v-list-item
          v-if="trendRadarAvailable"
          :to="optTrendRadarTarget"
          class="nav-item"
          data-testid="trendradar-nav-item"
        >
          <v-list-item-icon>
            <to-icon size="20px">radar</to-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              {{ $t('components.navigation.sidebar.trendradar') }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <!-- INNOVATION FIELDS -->
        <v-list-item
          v-if="optShowInnovationFields && hasInnovationFieldFeature"
          :to="{ name: 'innovation-fields' }"
          class="nav-item"
          data-test-id="innovation-fields-nav-item"
        >
          <v-list-item-icon>
            <to-icon size="20px">innovation</to-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              {{ $t('components.navigation.sidebar.innovationFields') }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <!-- INNOVATION PROJECTS -->
        <v-list-item
          v-if="optShowInnovationProjects && hasInnovationProjectFeature"
          :to="{ name: 'innovation-projects' }"
          class="nav-item"
        >
          <v-list-item-icon>
            <to-icon size="20px">map-sign</to-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              {{ $t('components.navigation.sidebar.innovationProject') }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <!-- INSIGHTS -->
        <v-list-item
          v-if="hasInsightsFeature"
          :to="{ name: 'insights' }"
          class="nav-item"
          data-test-id="insights-nav-item"
        >
          <v-list-item-icon>
            <to-icon size="20px">insights</to-icon>
            <counter-bean
              v-if="mini !== false && !mobile && insightsUpdates > 0"
              class="counter-bean navigation-bean"
            >
              {{ insightsUpdates }}
            </counter-bean>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              {{ $t('components.navigation.sidebar.insights') }}
            </v-list-item-title>
          </v-list-item-content>
          <counter-bean
            v-if="insightsUpdates > 0"
            class="mr-2 navigation-bean-expanded"
          >
            {{ insightsUpdates }}
          </counter-bean>
        </v-list-item>
      </v-list>

      <v-list
        class="main-nav-list justify-end"
        dense
      >
        <!-- TREND UPDATES -->
        <v-list-item
          @click="update"
        >
          <v-list-item-icon>
            <v-icon
              color="primary"
              size="20px"
            >
              mdi-information
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              {{ $t('components.navigation.sidebar.trendUpdate') }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <!-- HELP -->
        <v-list-item
          :href="$t('components.navigation.sidebar.help.url')"
          tag="a"
          target="_blank"
          @click="tracking('help')"
        >
          <v-list-item-icon>
            <v-icon
              color="orange"
              size="20px"
            >
              mdi-help-circle
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              {{ $t('components.navigation.sidebar.help.title') }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <!-- FEEDBACK -->
        <v-list-item
          @click="feedback"
        >
          <v-list-item-icon>
            <v-icon
              color="primary"
              size="20px"
            >
              mdi-chat-processing-outline
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              {{ $t('components.navigation.sidebar.feedback') }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <!-- PRODUCT UPDATES -->
        <v-list-item
          :href="$t('components.navigation.sidebar.productUpdate.url')"
          tag="a"
          target="_blank"
          @click="onClickProductUpdates('product-update')"
        >
          <v-list-item-icon>
            <to-icon size="20px">updates</to-icon>
            <counter-bean
              v-if="mini !== false && !mobile && featureUpdates > 0"
              class="counter-bean navigation-bean"
            >
              {{ featureUpdates }}
            </counter-bean>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              {{ $t('components.navigation.sidebar.productUpdate.title') }}
            </v-list-item-title>
          </v-list-item-content>
          <counter-bean
            v-if="featureUpdates > 0"
            class="mr-2 navigation-bean-expanded"
          >
            {{ featureUpdates }}
          </counter-bean>
        </v-list-item>

        <template v-if="showLocalesNavigation">
          <v-divider/>
          <locale-navigation
            :mini="mini"
          />
        </template>

        <v-divider/>
        <context-navigation
          :mini="mini"
        />
      </v-list>
    </v-row>
  </v-navigation-drawer>
</template>

<script>
import { Component, Prop } from 'vue-property-decorator'
import { mixins } from 'vue-class-component'
import { namespace } from 'vuex-class'
import { FeatureCheckMixin, OptionalMixin } from '@/mixins'

import ContextNavigation from '@/components/navigation/sidebar/context'
import CreateNavigation from '@/components/navigation/sidebar/create'
import LocaleNavigation from '@/components/navigation/sidebar/locale'
import ToIcon from '@/components/to-icon/to-icon'

import {
    COMPANY_MACRO_MEGA_TREND,
    COMPANY_MICRO_TREND,
    INNOVATION_FIELD,
    INNOVATION_PROJECT,
    TRENDRADAR,
    WORKSPACE
} from '@/model/views/features'
import {
    GET_COMPANY_FEATURES,
    GET_COMPANY_LOCALES,
    GET_FEATURE_UPDATES,
    GET_INSIGHTS_UPDATES
} from '@/store/auth/getter-types'
import CounterBean from '@/components/counter-bean/counter-bean'
import { SET_SHOW_UPDATE_DIALOG, SHOW_CREATE_FEEDBACK } from '@/store/gui/mutation-types'
import { SET_LAST_FEATURE_UPDATE } from '@/store/gui/action-types'

const authStore = namespace('auth')
const guiStore = namespace('gui')

@Component({
    components: {
        CounterBean,
        ContextNavigation,
        CreateNavigation,
        LocaleNavigation,
        ToIcon
    }
})
export default class NavigationSidebar extends mixins(FeatureCheckMixin, OptionalMixin) {
  @Prop({ type: Boolean }) value
  @Prop({
      type: Boolean,
      default: () => false
  }) mobile

  @authStore.Getter(GET_COMPANY_LOCALES) companyLocales
  @authStore.Getter(GET_COMPANY_FEATURES) companyFeatures
  @authStore.Getter(GET_INSIGHTS_UPDATES) insightsUpdates
  @authStore.Getter(GET_FEATURE_UPDATES) featureUpdates

  @guiStore.Action(SET_LAST_FEATURE_UPDATE) setLastFeatureUpdate
  @guiStore.Mutation(SHOW_CREATE_FEEDBACK) showFeedback
  @guiStore.Mutation(SET_SHOW_UPDATE_DIALOG) setShowUpdateDialog

  mini = true

  get internal () {
      return this.value
  }

  set internal (value) {
      this.$emit('input', value)
  }

  get showLocalesNavigation () {
      return this.companyLocales && this.companyLocales.length > 1
  }

  get showCreateNavigation () {
      const menuFeatures = [
          COMPANY_MICRO_TREND,
          COMPANY_MACRO_MEGA_TREND,
          TRENDRADAR,
          INNOVATION_FIELD,
          INNOVATION_PROJECT,
          WORKSPACE
      ]

      return this.companyFeatures.some(item => menuFeatures.includes(item))
  }

  async tracking (what) {
      this.$track(what, 'click')
  }

  async onClickProductUpdates (what) {
      await this.setLastFeatureUpdate()
      await this.tracking(what)
  }

  get trendRadarAvailable () {
      const priviledged = this.hasTrendRadarFeature || this.$can('read', 'PublishedTrendRadar')
      const showTrendRadars = this.optShowTrendRadars

      return showTrendRadars && priviledged
  }

  feedback () {
      this.showFeedback()
      this.tracking('feedback')
  }

  update () {
      this.setShowUpdateDialog(true)
      this.tracking('trendUpdate')
  }
}
</script>
