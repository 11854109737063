import { Quill } from 'vue2-editor'
import { linkSanitizer } from '@/util/link-sanitizer'

const Link = Quill.import('formats/link')
const Delta = Quill.import('delta')
const Break = Quill.import('blots/break')
const Embed = Quill.import('blots/embed')

Link.sanitize = linkSanitizer

export const lineBreakMatcher = () => {
    const newDelta = new Delta()
    newDelta.insert({ break: '' })
    return newDelta
}

class SmartBreak extends Break {
    length () {
        return 1
    }

    value () {
        return '\n'
    }

    insertInto (parent, ref) {
        Embed.prototype.insertInto.call(this, parent, ref)
    }
}

SmartBreak.blotName = 'break'
SmartBreak.tagName = 'BR'

Quill.register(SmartBreak)

export default Quill
