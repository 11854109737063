
import { Component, Prop, VModel, Vue } from 'vue-property-decorator'
import UpdateInfo from '@/components/universe-updates/update-info.vue'
import TrendUpdates from '@/components/universe-updates/trend-updates/index.vue'
import TrendLoader from '@/components/universe-updates/trend-updates/trend-loader.vue'
import { Locale } from '@/graphql/app/types'

@Component({
    components: {
        TrendLoader,
        TrendUpdates,
        UpdateInfo
    }
})
export default class UniverseUpdatesDialog extends Vue {
  @VModel({ type: Boolean, required: true }) open!: boolean
  @Prop({ type: Boolean, required: true }) admin!: boolean
  @Prop({ type: String, required: true }) locale!: Locale

  showUpdates = false

  onShowUpdates () {
      this.showUpdates = true
  }

  onClose () {
      this.$emit('close')
  }

  onGoToAdmin () {
      this.$emit('go-to-admin')
  }
}
