export const filterTypes = {
    INNOVATION_FIELDS: 'innovationField',
    INNOVATION_PROJECTS: 'innovationProject',
    TRENDRADARS: 'trendRadar'
}

export const filters = [
    filterTypes.INNOVATION_FIELDS,
    filterTypes.INNOVATION_PROJECTS,
    filterTypes.TRENDRADARS
]

export const initFilter = () => ({
    [filterTypes.INNOVATION_PROJECTS]: null,
    [filterTypes.TRENDRADARS]: null
})
