import { DEFAULT_SNACKBAR_TIMEOUT } from '@/model/constants'

import { TABS, WORKSPACE } from '@/model/trendradar/tabs'

import Vue from 'vue'
import * as MUTATION from './mutation-types'
import * as util from './util'

export default {
    [MUTATION.SET_MICROTREND]: (state, payload) => {
        Vue.set(state, 'microtrend', payload)
    },

    [MUTATION.RESET_MICROTREND]: state => {
        Vue.set(state, 'microtrend', null)
    },

    [MUTATION.HIDE_SNACKBAR]: state => {
        Vue.set(state, 'snackbar', util.initSnackbar())
    },

    [MUTATION.SHOW_SNACKBAR]: (state, payload) => {
        const messageContainer = {
            show: true,
            mode: 'feedback',
            targets: [],
            messages: [],
            timeout: DEFAULT_SNACKBAR_TIMEOUT
        }

        if (typeof payload === 'string') {
            messageContainer.messages = [payload]
        }

        if (typeof payload === 'object') {
            if (payload.messages && Array.isArray(payload.messages.graphQLErrors)) {
                messageContainer.messages = payload.messages.graphQLErrors.map(elem => elem.message)
                messageContainer.mode = payload.mode || 'error'
                messageContainer.timeout = payload.timeout || DEFAULT_SNACKBAR_TIMEOUT
            }

            if (Array.isArray(payload.messages)) {
                messageContainer.messages = payload.messages
                messageContainer.mode = payload.mode || 'feedback'
                messageContainer.timeout = payload.timeout || DEFAULT_SNACKBAR_TIMEOUT
            }

            if (Array.isArray(payload.targets)) {
                messageContainer.targets = [
                    ...payload.targets
                ]
            }
        }

        if (payload && Array.isArray(payload)) {
            messageContainer.messages = payload
        }

        if (messageContainer.messages.length > 0) {
            Vue.set(state, 'snackbar', messageContainer)
        } else {
            console.log('%cError showSnackbar: No messages found!?', 'color: red;', payload) // eslint-disable-line no-console
        }
    },

    [MUTATION.HIDE_PROPOSE_INNOVATION_PROJECT]: state => {
        Vue.set(
            state,
            'proposeInnovationProject',
            util.initProposeInnovationProject()
        )
    },

    [MUTATION.SHOW_PROPOSE_INNOVATION_PROJECT]: state => {
        Vue.set(
            state,
            'proposeInnovationProject',
            util.initProposeInnovationProject(true)
        )
    },

    [MUTATION.SHOW_ADD_TO_WORKSPACE]: (state, payload) => {
        const add = util.initAdd()
        add.workspaces = [...payload]
        Vue.set(state, 'add', add)
    },

    [MUTATION.SHOW_ADD_TO_INNOVATIONFIELD]: (state, payload) => {
        const add = util.initAdd()
        add.innovationField = [...payload]
        Vue.set(state, 'add', add)
    },

    [MUTATION.SHOW_CREATE_SEARCH_AGENT]: state => {
        Vue.set(state.create, 'searchAgent', true)
    },

    [MUTATION.HIDE_ADD_GENERIC]: state => {
        Vue.set(state, 'pin', util.initPin())
        Vue.set(state, 'add', util.initAdd())
        Vue.set(state, 'create', util.initCreate())
    },

    [MUTATION.SHOW_TRENDRADAR_CREATE_RELATED]: (state, payload) => {
        if (payload && 'context' in payload) {
            Vue.set(state.create.trendradarRelated, 'context', payload.context)
        } else {
            Vue.set(state.create.trendradarRelated, 'context', null)
        }
        Vue.set(
            state.create.trendradarRelated,
            'tab',
            payload && TABS.includes(payload.tab) ? payload.tab : WORKSPACE
        )
        Vue.set(state.create.trendradarRelated, 'show', true)
    },

    [MUTATION.SELECT_TRENDRADAR_CREATE_RELATED]: (state, payload) => {
        Vue.set(state.create.trendradarRelated, 'tab', payload)
    },

    [MUTATION.SET_MICROTRENDS_TO_PIN]: (state, payload) => {
        Vue.set(state, 'pin', util.initPin(payload))
    },

    [MUTATION.SET_BACK_URL]: (state, payload) => {
        Vue.set(state, 'backUrl', payload)
    },

    [MUTATION.SET_VIEW_MODE]: (state, payload) => {
        state.viewConfig.views = {
            ...state.viewConfig.views,
            [payload.id]: payload.mode
        }
    },

    [MUTATION.SET_VIEW_CONFIG]: (state, payload) => {
        state.viewConfig.configs[payload.id] = [
            ...payload.config
        ]
    },

    [MUTATION.SET_PAGINATION]: (state, payload) => {
        state.viewConfig.pagination[payload.id] = {
            ...payload.pagination
        }
    },

    [MUTATION.UPDATE_PAGINATION]: (state, payload) => {
        const o = state.viewConfig.pagination[payload.id] ? state.viewConfig.pagination[payload.id] : {}
        const n = {}

        if (payload.pageInfo && payload.pageInfo.currentPage) {
            n.page = parseInt(payload.pageInfo.currentPage)
        }
        if (payload.pageInfo && payload.pageInfo.pageSize) {
            n.rowsPerPage = parseInt(payload.pageInfo.pageSize)
        }
        if (payload.pageInfo && payload.pageInfo.totalCount) {
            n.totalItems = parseInt(payload.pageInfo.totalCount)
        }

        Vue.set(state.viewConfig.pagination, payload.id, Object.assign({}, o, n))
    },

    [MUTATION.SHOW_CREATE_FEEDBACK]: state => {
        Vue.set(state.create, 'feedback', true)
    },

    [MUTATION.HIDE_CREATE_FEEDBACK]: state => {
        state.create.feedback = false
    },

    [MUTATION.SET_LAST_FEATURE_UPDATE]: (state, payload) => {
        Vue.set(state, 'lastFeatureUpdate', payload)
    },

    [MUTATION.CLOSE_FEATURE_UPDATE_DIALOG]: (state) => {
        Vue.set(state, 'featureUpdateDialog', false)
    },

    [MUTATION.SET_SHOW_UPDATE_DIALOG]: (state, payload) => {
        Vue.set(state, 'universeUpdateDialog', payload)
    },

    [MUTATION.SET_WORKSPACE_DEPRECATED_DIALOG]: (state, payload) => {
        Vue.set(state, 'workspaceDeprecatedDialog', payload)
    }
}
