
import { Component, Vue } from 'vue-property-decorator'
import { LogoutMixin } from '@/mixins'
import NavigationAdminSidebar from './sidebar.vue'

// @ts-ignore
@Component({
    components: {
        NavigationAdminSidebar
    },
    mixins: [
        LogoutMixin
    ]
})
export default class NavigationAdmin extends Vue {
}
