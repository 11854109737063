<template>
  <v-dialog
    v-model="show"
    max-width="650"
    persistent
  >
    <loading-circle
      v-if="loading"
    />
    <workspace-form
      v-else
      v-model="workspace"
      :pending="pending"
      :participantsOnly="participantsOnly"
      :workspace-id="workspaceId"
      @submit="onSubmit"
      @close="onCloseOverlay"
    />
  </v-dialog>
</template>

<script>
import createGQL from '@/graphql/app/workspace/mutation/create.gql'
import editGQL from '@/graphql/app/workspace/query/edit.gql'
import updateGQL from '@/graphql/app/workspace/mutation/update.gql'

import LoadingCircle from '@/components/loading-circle/loading-circle'
import WorkspaceForm from '@/components/workspace/form/form'

import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import { initWorkspace } from '@/model/workspace/initial'

import { SHOW_SNACKBAR } from '@/store/gui/mutation-types'
import { HIDE_ALL_DIALOGS } from '@/store/workspace/mutation-types'
import { GET_USER_ID } from '@/store/auth/getter-types'
import { GET_WORKSPACE_EDIT } from '@/store/workspace/getter-types'

const authStore = namespace('auth')
const guiStore = namespace('gui')
const workspaceStore = namespace('workspace')

@Component({
    apollo: {
        workspace: {
            query: editGQL,
            fetchPolicy: 'network-only',
            variables () {
                return {
                    id: this.workspaceId
                }
            },
            skip () {
                return this.workspaceId === null
            },
            update (data) {
                return {
                    title: data.node.title,
                    description: data.node.description,
                    participantIds: data.node.participants.nodes.map(p => p.id).filter(id => id !== this.uid)
                }
            }
        }
    },
    components: {
        LoadingCircle,
        WorkspaceForm
    }
})
export default class WorkspaceEdit extends Vue {
  @authStore.Getter(GET_USER_ID) uid

  @guiStore.Mutation(SHOW_SNACKBAR) showSnackbar

  @workspaceStore.Getter(GET_WORKSPACE_EDIT) edit
  @workspaceStore.Mutation(HIDE_ALL_DIALOGS) hideOverlay

  workspace = initWorkspace()
  pending = false

  get loading () {
      return this.$apollo.queries.workspace.loading
  }

  get workspaceId () {
      return this.edit.workspace
          ? this.edit.workspace.id
          : null
  }

  get participantsOnly () {
      return this.edit.workspace && this.edit.workspace.participantsOnly
          ? this.edit.workspace.participantsOnly
          : null
  }

  get show () {
      return this.edit.show
  }

  set show (v) {
      this.onCloseOverlay()
  }

  async onSubmit () {
      await this.workspaceId ? this.update() : this.create()
  }

  async create () {
      try {
          this.pending = true
          const response = await this.$apollo.mutate({
              mutation: createGQL,
              variables: {
                  input: this.workspace
              }
          })
          this.$track('Workspace', 'create')
          this.showSnackbar(
              this.$t('components.workspace.edit.create.success', { title: response.data.createWorkspace.title })
          )
          this.onCloseOverlay()
      } catch (e) {
      // eslint-disable-next-line no-console
          console.log('%cError on create workspace:', 'color: red;', e)
      } finally {
          this.pending = false
      }
  }

  async update () {
      try {
          this.pending = true
          const response = await this.$apollo.mutate({
              mutation: updateGQL,
              variables: {
                  id: this.workspaceId,
                  input: {
                      title: this.workspace.title,
                      description: this.workspace.description,
                      participantIds: this.workspace.participantIds.concat(this.uid)
                  }
              }
          })
          this.$track('Workspace', 'update')
          this.showSnackbar({
              type: 'feedback',
              messages: [this.$t('components.workspace.edit.update.success', { title: response.data.workspace.update.title })]
          })
          this.onCloseOverlay()
      } catch (error) {
      // eslint-disable-next-line no-console
          console.log('%cError on update workspace:', 'color: red;', error)
      } finally {
          this.pending = false
      }
  }

  onCloseOverlay () {
      this.hideOverlay()
      this.workspace = initWorkspace()
  }
}
</script>
