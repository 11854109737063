import { Vue, Component } from 'vue-property-decorator'

import { CATEGORIES } from '@/model/post/categories'

@Component
export default class ThreadMixin extends Vue {
    initCategories () {
        return CATEGORIES.map(cat => ({
            category: cat,
            cnt: 0
        }))
    }

    sumCategories (node) {
        return CATEGORIES.map(cat => {
            return node[cat]
                ? {
                    category: cat,
                    cnt: node[cat].pageInfo.totalCount
                }
                : {
                    category: cat,
                    cnt: 0
                }
        })
    }
}
