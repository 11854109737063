<template>
  <confirmation-dialog
    v-model="show"
    v-if="show"
    :title="$t('components.workspace.copy.title', { title: workspace.title })"
    :content="$t('components.workspace.copy.content')"
    :confirm="$t('components.workspace.copy.confirm')"
    :confirmation-disabled="disabled"
    color="primary"
    @confirmed="onConfirmed"
    :pending="pending"
  >
    <v-form>
      <v-text-field
        v-model="copyTitle"
        :label="$t('components.workspace.copy.form.title.label')"
      />
    </v-form>
  </confirmation-dialog>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import { HIDE_ALL_DIALOGS } from '@/store/workspace/mutation-types'
import { SHOW_SNACKBAR } from '@/store/gui/mutation-types'

import copyGQL from '@/graphql/app/workspace/mutation/copy.gql'

import ConfirmationDialog from '@/components/confirmation-dialog/confirmation-dialog'
import { GET_CURRENT_WORKSPACE, IS_COPY } from '@/store/workspace/getter-types'
import { GET_LOCALE } from '@/store/auth/getter-types'

const guiStore = namespace('gui')
const workspaceStore = namespace('workspace')
const authStore = namespace('auth')

@Component({
    components: {
        ConfirmationDialog
    }
})
export default class WorkspaceCopyDialog extends Vue {
  @guiStore.Mutation(SHOW_SNACKBAR) showSnackbar

  @authStore.Getter(GET_LOCALE) locale

  @workspaceStore.Getter(IS_COPY) copy
  @workspaceStore.Getter(GET_CURRENT_WORKSPACE) workspace
  @workspaceStore.Mutation(HIDE_ALL_DIALOGS) hide

  pending = false
  copyTitle = null

  get show () {
      return this.copy && this.workspace
  }

  set show (value) {
      this.hide()
  }

  get disabled () {
      return this.copyTitle === null || this.copyTitle === ''
  }

  async onConfirmed () {
      try {
          this.pending = true
          await this.$apollo.mutate({
              mutation: copyGQL,
              variables: {
                  id: this.workspace.id,
                  title: this.copyTitle
              }
          })
          this.$track('Workspace', 'copy')
          this.showSnackbar(this.$t('components.workspace.copy.success'))
          this.hide()
          await this.$router.push({ name: 'workspace' })
      } catch (error) {
      // eslint-disable-next-line no-console
          console.log('%cError on copy workspace:', 'color: red;', error)
      } finally {
          this.pending = false
      }
  }

  updated () {
      if (this.copyTitle === null) {
          this.copyTitle = `${this.$t('components.workspace.copy.titlePrefix')} ${this.workspace.title}`
      }
  }
}
</script>
