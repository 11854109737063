<template>
  <v-card
    v-if="comment"
    class="microtrend-comment"
    flat
    tile
  >
    <v-card-title>
      <h3>{{ $t('components.microtrend.comment.title') }}</h3>
    </v-card-title>
    <v-card-text
      class="pt-3"
      v-html="comment"
    />
  </v-card>
</template>

<script>
import { Component, Prop, Vue } from 'vue-property-decorator'
import { COMPANY_MICROTREND } from '@/model/types'

@Component
export default class CompanyMicrotrendComment extends Vue {
  @Prop({
      type: Object,
      validator: obj => obj.__typename === COMPANY_MICROTREND
  }) obj

  get comment () {
      return this.obj.comment
  }
}
</script>
