import { Component, Vue } from 'vue-property-decorator'
import {
    AI_BETA,
    COMPANY_MACRO_MEGA_TREND,
    COMPANY_MICRO_TREND,
    INNOVATION_FIELD,
    INNOVATION_PROJECT,
    INSIGHTS,
    PINS,
    PUBLIC_INNOVATION_FIELDS,
    PUBLIC_INNOVATION_PROJECTS,
    SEARCH_AGENT,
    TRENDRADAR,
    TRENDRADAR_PUBLIC_EVALUATION,
    WORKSPACE
} from '@/model/views/features'
import { Feature } from '@/graphql/app/types'

@Component
export default class FeatureCheckMixin extends Vue {
    get hasTrendRadarFeature () {
    // @ts-ignore
        return this.$can('use-feature', TRENDRADAR)
    }

    get hasWorkspaceFeature () {
    // @ts-ignore
        return this.$can('use-feature', WORKSPACE)
    }

    get hasSearchAgentFeature () {
    // @ts-ignore
        return this.$can('use-feature', SEARCH_AGENT)
    }

    get hasInnovationFieldFeature () {
    // @ts-ignore
        return this.$can('use-feature', INNOVATION_FIELD)
    }

    get hasCompanyMicroTrendFeature () {
    // @ts-ignore
        return this.$can('use-feature', COMPANY_MICRO_TREND)
    }

    get hasPinsFeature () {
    // @ts-ignore
        return this.$can('use-feature', PINS)
    }

    get hasCompanyMacroMegaTrendFeature () {
    // @ts-ignore
        return this.$can('use-feature', COMPANY_MACRO_MEGA_TREND)
    }

    get hasInnovationProjectFeature () {
    // @ts-ignore
        return this.$can('use-feature', INNOVATION_PROJECT)
    }

    get hasInsightsFeature () {
    // @ts-ignore
        return this.$can('use-feature', INSIGHTS)
    }

    get hasPublicInnovationFieldsFeature () {
    // @ts-ignore
        return this.$can('use-feature', PUBLIC_INNOVATION_FIELDS)
    }

    get hasPublicInnovationProjectsFeature () {
    // @ts-ignore
        return this.$can('use-feature', PUBLIC_INNOVATION_PROJECTS)
    }

    get hasTrendRadarPublicEvaluationFeature () {
    // @ts-ignore
        return this.$can('use-feature', TRENDRADAR_PUBLIC_EVALUATION) && this.hasTrendRadarFeature
    }

    get hasAiBetaFeature () {
    // @ts-ignore
        return this.$can('use-feature', AI_BETA)
    }

    get hasAiCompanyTrendsFeature () {
    // @ts-ignore
        return this.$can('use-feature', Feature.AiCompanyTrends)
    }
}
