<template>
  <v-list-group
    no-action
    v-model="state"
  >
    <template v-slot:activator>
      <v-list-item
        class="nav-item"
        style="flex: 1 1 auto !important; overflow: hidden;"
      >
        <v-list-item-icon
        >
          <v-avatar
            :size="22"
          >
            <img
              :src="avatar"
              :alt="name"
            >
          </v-avatar>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ name }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>

    <v-list
      class="main-nav-list inner-nav-list"
      dense
    >
      <v-list-item
        exact
        :to="{ name: 'profile' }"
      >
        <v-list-item-title>
          {{ $t('components.navigation.context.accountsettings') }}
        </v-list-item-title>
      </v-list-item>

      <v-list-item
        v-if="showAdminLink"
        :to="{ name: 'admin-dashboard' }"
        exact
      >
        <v-list-item-content>
          <v-list-item-title>
            {{ $t('components.navigation.context.administration') }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider/>

      <v-list-item
        @click="handleLogout"
      >
        <v-list-item-content>
          <v-list-item-title>
            {{ $t('components.navigation.context.logout') }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-list-group>
</template>

<script>
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { GET_AVATAR, GET_FULLNAME } from '@/store/auth/getter-types'
import * as TYPE from '@/model/types'
import { LogoutMixin } from '@/mixins'

const authStore = namespace('auth')

@Component({
    mixins: [
        LogoutMixin
    ]
})
export default class NavigationContext extends Vue {
  @Prop({
      type: Boolean,
      default: () => false
  }) mini

  @authStore.Getter(GET_AVATAR) avatar
  @authStore.Getter(GET_FULLNAME) name

  state = false

  @Watch('mini')
  onChangeMini (value) {
      if (value) {
          this.state = false
      }
  }

  get showAdminLink () {
      return this.$can('manage', TYPE.COMPANY_MEGATREND) ||
      this.$can('manage', TYPE.USER)
  }
}
</script>
