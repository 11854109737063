import * as ACTION from './action-types'

import {
    CHANGE_VIEW,
    SET_MODE,
    RESET_DETAILS, CHANGE_CHART_CONFIGURATION
} from './mutation-types'

const actions = {
    [ACTION.CHANGE_CHART_CONFIGURATION]: ({ commit }, payload) => {
        if (payload.size === null) {
            commit(RESET_DETAILS)
        }
        commit(CHANGE_CHART_CONFIGURATION, payload)
    },

    [ACTION.CHANGE_VIEW]: ({ commit }, payload) => {
        commit(RESET_DETAILS)
        commit(CHANGE_VIEW, payload)
    },

    [ACTION.UPDATE_MODE]: ({ commit }, payload) => {
        commit(RESET_DETAILS)
        commit(SET_MODE, payload)
    }
}

export default actions
