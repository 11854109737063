
import { Component, Prop, VModel, Vue } from 'vue-property-decorator'
import { SERVICE_PROVISION, VISITOR_STATISTICS } from '@/components/consent-management/cookie-consent/cookie-options'
import { SHOW_DATA_PROTECTION } from '@/model/events'
import { AUTHENTICATION, MATOMO } from '@/model/flags'

@Component<ManageCookies>({
})
export default class ManageCookies extends Vue {
  @Prop({ required: true }) matomoSelected!: boolean
  @VModel({ required: true }) show!: boolean

  matomoUpdated: boolean | null = null

  get selected (): boolean {
      return this.matomoUpdated !== null ? this.matomoUpdated : this.matomoSelected
  }

  saveSelected (): void {
      this.$emit('cookies:save:matomoSelected', this.matomoUpdated)
      this.show = false
  }

  openPrivacyPolicy (): void {
      // @ts-ignore
      this.$root.$emit(SHOW_DATA_PROTECTION)
  }

  toggleMatomo (): void {
      this.matomoUpdated = !this.selected
  }

  categories (): { provision: string, statistics: string } {
      return {
          provision: SERVICE_PROVISION,
          statistics: VISITOR_STATISTICS
      }
  }

  flags (): { authentication: string, matomo: string } {
      return {
          authentication: AUTHENTICATION,
          matomo: MATOMO
      }
  }

  t (path: string): string {
      return this.$t(`components.consentManagement.cookieConsent.manageCookies.${path}`) as string
  }
}
