import { Component, Vue } from 'vue-property-decorator'

@Component
export default class NumberFormatMixin extends Vue {
    isNumeric (value) {
        return !isNaN(parseFloat(value)) && isFinite(value)
    }

    numberFormat (value, divisor, locale = 'de', empty) {
        return this.isNumeric(value)
            ? (new Intl.NumberFormat(locale, {
                style: 'decimal',
                maximumFractionDigits: 1,
                minimumFractionDigits: 1
            }).format(Number(value) / divisor))
            : empty
    }
}
