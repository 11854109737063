export const LOCALE_EN = 'en'
export const LOCALE_DE = 'de'

export const LOCALES_MAP = {
    [LOCALE_DE]: 'deutsch',
    [LOCALE_EN]: 'englisch'
}

export const LOCALES = [LOCALE_DE, LOCALE_EN]

export const LOCALES_LIST = Object.keys(LOCALES_MAP).map(key => ({ name: LOCALES_MAP[key], value: key }))
