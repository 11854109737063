
import { Component, Prop, Vue } from 'vue-property-decorator'
import CriteriaSetLoader from '@/components/criteria-set/criteria-set-loader.vue'
import LoadingCircle from '@/components/loading-circle/loading-circle.vue'
import TrendradarCreateForm from '@/components/trendradar/create/trendradar-create-form.vue'
import { CreateTrendRadarInput, Locale } from '@/graphql/app/types'
import TrendradarDraftHandler from '@/components/trendradar/handler/trendradar-draft-handler.vue'

const init = (): CreateTrendRadarInput => ({
    title: '',
    description: '',
    criteriaSetId: '',
    managerIds: []
})

@Component({
    components: {
        TrendradarDraftHandler,
        CriteriaSetLoader,
        LoadingCircle,
        TrendradarCreateForm
    }
})
export default class TrendradarCreate extends Vue {
  @Prop({ required: true }) locale!: Locale

  show = false

  trendradar: CreateTrendRadarInput = init()

  t (token: string): string {
      const prefix = 'components.trendradar.create'
      return this.$t(`${prefix}.${token}`).toString()
  }

  onCancel () {
      this.trendradar = init()
      this.show = false
  }

  async onCreateSuccess (id: string) {
      this.onCancel()
      await this.$router.push({
          name: 'trendradar-dashboard',
          params: {
              trendradarDraftId: id
          }
      })
  }
}
