import Vue from 'vue'
import * as Mutation from './mutation-types'

export default {
    [Mutation.HIDE_ALL_DIALOGS]: (state) => {
        Vue.set(state, 'delete', false)
        Vue.set(state, 'edit', false)
        Vue.set(state, 'currentSearchAgent', null)
        Vue.set(state, 'currentWorkspace', null)
    },
    [Mutation.SHOW_DELETE_DIALOG]: (state, payload) => {
        Vue.set(state, 'delete', true)
        Vue.set(state, 'currentWorkspace', payload.currentWorkspace)
        Vue.set(state, 'currentSearchAgent', payload.currentSearchAgent)
    },
    [Mutation.SHOW_EDIT_DIALOG]: (state, payload) => {
        Vue.set(state, 'edit', true)
        Vue.set(state, 'currentWorkspace', payload.currentWorkspace)
        Vue.set(state, 'currentSearchAgent', payload.currentSearchAgent ? payload.currentSearchAgent : null)
    },
    [Mutation.UPDATE_CURRENT_PAGE_INFO]: (state, payload) => {
        Vue.set(state, 'currentPageInfo', payload)
    }
}
