<template>
  <div
    v-if="show"
    class="industries"
  >
    <div>
      <p class="d-inline">{{ $t('components.microtrend.overlay.industries') }}</p>
      <v-btn
        v-if="$can('update', microtrend)"
        small
        rounded
        icon
        text
        color="primary"
        class="ma-0 float-right"
        @click="onClick"
      >
        <to-icon>edit</to-icon>
      </v-btn>
    </div>
    <p>
      <trend-tag
        v-for="industry in industries"
        :to="{ name: 'trendfinder', query: { f: industry.id }}"
        :key="industry.id"
        :node="industry"
      />
    </p>
  </div>
</template>

<script>
import { Component, Prop, Vue } from 'vue-property-decorator'

import { MICROTRENDS } from '@/model/types'
import { EDIT } from '@/model/views/modes'

import TrendTag from '@/components/trend-tag'
import ToIcon from '@/components/to-icon/to-icon'

@Component({
    components: {
        TrendTag,
        ToIcon
    }
})
export default class IndustriesView extends Vue {
  @Prop({
      type: Object,
      validator: obj => MICROTRENDS.includes(obj.__typename)
  }) microtrend

  get industries () {
      return this.microtrend.industries || []
  }

  get show () {
      return this.industries.length > 0 || this.$can('update', this.microtrend)
  }

  onClick () {
      this.$emit('change-mode', EDIT)
  }
}
</script>
