import { appClient as apollo } from '@/plugins/apollo'
import createGQL from '@/graphql/app/trendradar/mutation/create.gql'
import loadParticipationGQL from '@/graphql/app/trendradar/query/load-participation.gql'
import evaluateMacrotrendGQL from '@/graphql/app/participation/mutation/evaluate-macrotrend.gql'

export const init = () => ({
    id: null,
    myParticipationId: null,
    title: null,
    description: null,
    managers: [],
    managerIds: [],
    macrotrends: {},
    image: null,
    evaluationEnabled: true,
    published: false,
    hasUnpublishedChanges: false,
    evaluations: [],
    sharingOptions: {},
    criteriaSet: null,
    criteriaSetId: null,
    criteriaSetChangeable: null,
    publicEvaluationOption: {}
})

export const create = async (createInput) => {
    const response = await apollo.mutate({
        mutation: createGQL,
        variables: {
            input: createInput
        }
    })

    return response.data.createTrendRadar
}

export const loadParticipation = async (id, locale) => {
    const response = await apollo.query({
        query: loadParticipationGQL,
        fetchPolicy: 'network-only',
        variables: {
            id: id,
            locale: locale
        }
    })

    return response.data.participation
}

export const evaluate = async (trendradarId, macrotrendId, values, locale) => {
    const vars = {
        input: {
            trendRadarId: trendradarId,
            macrotrendId: macrotrendId,
            values: values
        },
        locale: locale
    }

    const response = await apollo.mutate({
        mutation: evaluateMacrotrendGQL,
        variables: vars
    })

    return response.data.evaluateMacrotrend
}
