<template>
  <v-dialog
    v-model="open"
    :content-class="contentClassName"
    :maxWidth="width"
    persistent
  >
    <v-card
      tile
      flat
    >
      <v-container fluid>
        <v-row>
          <v-col cols="11" class="pl-3">
            <h3 v-if="title">{{ title }}</h3>
          </v-col>
          <v-col cols="1" class="d-flex align-top">
            <v-btn
              icon small
              @click="open = false"
            >
              <v-icon small>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
      <v-card-text
        v-if="content"
        v-html="content"
      />

      <v-card-text
        v-if="showDefaultSlot"
      >
        <slot></slot>
      </v-card-text>

      <v-card-text
        v-if="$slots.actions"
      >
        <slot name="actions"></slot>
      </v-card-text>

      <v-card-text
        v-if="notice"
        v-html="notice"
      />

      <v-card-actions>
        <v-btn
          @click="onClose"
          :color="color"
          :disabled="pending"
          aria-label="close-button"
          text
          depressed
        >
          {{ $t('components.confirmationDialog.close') }}
        </v-btn>
        <v-spacer/>

        <v-btn
          @click.native.prevent="onConfirm"
          :color="color"
          :dark="!pending"
          :light="pending"
          :disabled="pending || confirmationDisabled"
          :loading="pending"
          aria-label="confirm-button-1"
          depressed
          class="confirm-btn"
        >
          {{ confirm }}
        </v-btn>
      </v-card-actions>
      <v-card-actions
        v-if="confirm2"
        class="pt-0"
      >
        <v-spacer/>
        <v-btn
          depressed
          :color="color2"
          :light="pending"
          :dark="!pending"
          :disabled="pending || confirmationDisabled"
          @click.native.prevent="onConfirmTwo"
          aria-label="confirm-button-2"
          class="confirm-btn"
        >
          {{ confirm2 }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { Component, Prop, VModel, Vue } from 'vue-property-decorator'

@Component
export default class ConfirmationDialog extends Vue {
  @VModel({
      type: Boolean,
      default: () => false
  }) open

  @Prop({
      type: Boolean,
      default: () => false
  }) pending

  @Prop({
      type: Boolean,
      default: () => false
  }) confirmationDisabled

  @Prop({
      type: String,
      required: true
  }) title

  @Prop({
      type: String,
      default: () => null
  }) notice

  @Prop({
      type: String,
      default: () => null
  }) content

  @Prop({
      type: String,
      default: ''
  }) contentClassName

  @Prop({
      type: String,
      default: () => null
  }) confirm

  @Prop({
      type: String,
      default: () => null
  }) confirm2

  @Prop({
      type: String,
      default: () => 'red'
  }) color

  @Prop({
      type: String,
      default: () => 'red'
  }) color2

  @Prop({
      type: String,
      default: () => '440'
  }) width

  get showDefaultSlot () {
      return this.$slots.default && this.$slots.default.length > 0
  }

  onClose () {
      this.open = false
      this.$emit('closed')
  }

  onConfirm () {
      this.$emit('confirmed')
  }

  onConfirmTwo () {
      this.$emit('confirmed2')
  }
}
</script>
