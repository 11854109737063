import * as Getter from './getter-types'

export default {
    [Getter.EXPORT_NODE_ID]: (state) => state.id,

    [Getter.EXPORT_DIALOG]: (state) => state.dialog,

    [Getter.EXPORT_TYPE]: (state) => state.type,

    [Getter.EXPORT_TITLE]: (state) => state.title
}
