<template>
  <v-dialog
    v-model="internal"
    width="600"
    persistent
  >
    <v-card tile>
      <form
        @reset.prevent="onReset"
        @submit.prevent="onEnter"
      >
        <v-card-title>
          <h4>{{ $t('components.companyMicrotrend.scraper.title') }}</h4>
        </v-card-title>
        <v-card-text>
          {{ $t('components.companyMicrotrend.scraper.intro') }}
        </v-card-text>
        <v-card-text>
          <v-text-field
            v-model="$v.url.$model"
            :label="$t('components.companyMicrotrend.scraper.label')"
            :disabled="pending"
            :error-messages="errors"
            @keyup.enter.prevent="onEnter"
            filled
          />
        </v-card-text>

        <v-card-actions class="pb-1">
          <v-btn
            type="reset"
            depressed
            :disabled="pending"
          >
            {{ $t('components.companyMicrotrend.scraper.action.reset') }}
          </v-btn>
          <v-spacer/>
          <v-btn
            outlined
            value="left"
            color="primary"
            :disabled="pending"
            @click.prevent="onSubmitLeft"
          >
            {{ $t('components.companyMicrotrend.scraper.action.submit') }}
          </v-btn>

          <v-btn
            type="submit"
            color="primary"
            value="right"
            :disabled="disabled"
            :loading="pending"
            @submit.prevent="onSubmitRight"
          >
            {{ $t('components.companyMicrotrend.scraper.action.submitUrl') }}
          </v-btn>
        </v-card-actions>
      </form>
    </v-card>
  </v-dialog>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { required, url } from 'vuelidate/lib/validators'

import scraperGQL from '@/graphql/app/microtrend/query/scraper.gql'

import { SET_SCRAPED_INFORMATION, SET_SCRAPER } from '@/store/microtrend/mutation-types'
import { GET_SCRAPER } from '@/store/microtrend/getter-types'

const microtrendStore = namespace('microtrend')

@Component({
    validations: {
        url: {
            required,
            url
        }
    }
})
export default class Scraper extends Vue {
  @microtrendStore.Getter(GET_SCRAPER) scraper
  @microtrendStore.Mutation(SET_SCRAPER) setScraper
  @microtrendStore.Mutation(SET_SCRAPED_INFORMATION) setScrapedInformation

  url = null
  pending = false

  get internal () {
      return this.scraper
  }

  set internal (value) {
      this.setScraper(value)
  }

  get disabled () {
      return this.$v.$invalid || this.pending
  }

  get errors () {
      const errors = []
      if (this.$v.$dirty) {
          if (!this.$v.url.required) {
              errors.push(this.$t('components.companyMicrotrend.scraper.error.required'))
          }

          if (!this.$v.url.url) {
              errors.push(this.$t('components.companyMicrotrend.scraper.error.url'))
          }
      }
      return errors
  }

  onSubmitLeft () {
      this.close()
      this.$router.push({ name: 'company-microtrend-create' })
  }

  async onEnter () {
      if (!this.disabled && this.url) {
          await this.onSubmitRight()
      }
  }

  async onSubmitRight () {
      try {
          this.pending = true

          const response = await this.$apollo.query({
              query: scraperGQL,
              variables: {
                  url: this.url
              }
          })
          this.setScrapedInformation(response.data.scrapedInfos)
          this.close()
          this.$router.push({ name: 'company-microtrend-create' })
      } catch (e) {
          console.log('%cError on scrape url: ', 'color: red;', e) // eslint-disable-line no-console
      } finally {
          this.pending = false
      }
  }

  close () {
      this.internal = false
      this.$v.$reset()
      this.url = null
  }

  onReset () {
      this.close()
  }
}
</script>
