export const AUTHOR = 'author'
export const COUNTRY = 'country'
export const DEFAULT = 'default'
export const DATE = 'date'
export const IDENTIFIED_AT = 'identified-at'
export const IMAGE = 'image/index'
export const INDUSTRY = 'industry'
export const INITIATORS = 'initiators'
export const INNOVATION_FIELDS = 'innovation-fields'
export const INNOVATION_PROJECTS = 'innovation-projects'
export const INNOVATION_TYPE = 'innovation-type'
export const MACROTRENDS = 'macrotrends'
export const MANAGERS = 'managers'
export const MEGATRENDS = 'megatrends'
export const NEW_COUNT = 'new-count'
export const PARTCIPANTS = 'participants'
export const STATE = 'state'
export const STRENGTH = 'strength'
export const TITLE = 'title'
export const TREND_COUNT = 'trend-count'
export const TRENDRADARS = 'trendradars'
export const UPDATED_AT = 'updated-at'
export const UNVOTED_COUNT = 'unvoted-count'

export const COLUMNS = [
    AUTHOR,
    COUNTRY,
    DEFAULT,
    DATE,
    IDENTIFIED_AT,
    IMAGE,
    INDUSTRY,
    INITIATORS,
    INNOVATION_FIELDS,
    INNOVATION_PROJECTS,
    INNOVATION_TYPE,
    MACROTRENDS,
    MANAGERS,
    MEGATRENDS,
    NEW_COUNT,
    PARTCIPANTS,
    STATE,
    STRENGTH,
    TITLE,
    TREND_COUNT,
    TRENDRADARS,
    UNVOTED_COUNT,
    UPDATED_AT
]
