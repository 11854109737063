<template>
  <v-app>
    <router-view
      :key="$route.fullPath"
    />
    <icons-lib/>
    <snackbar/>
    <navigation-footer/>
  </v-app>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { RULES } from '@/store/auth/getter-types'

import CookieConsent from '@/components/consent-management/cookie-consent'
import NavigationFooter from '@/components/navigation/footer/footer'
import IconsLib from '@/components/to-icon/lib'
import Snackbar from '@/components/snackbar/snackbar'

const auth = namespace('auth')

@Component({
    components: {
        CookieConsent,
        NavigationFooter,
        IconsLib,
        Snackbar
    },
    metaInfo () {
        return {
            title: '',
            titleTemplate (title) {
                return title
                    ? `${title} | TRENDMANAGER | TRENDONE`
                    : 'TRENDMANAGER | TRENDONE'
            }
        }
    }
})
export default class App extends Vue {
  @auth.Getter(RULES) rules

  beforeMount () {
      this.$ability.update(this.rules)
  }
}
</script>

<style
  lang="css"
  src="./styles/theme.css"
/>

<style
  lang="sass"
  src="./styles/main.sass"
/>
