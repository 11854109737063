<template>
  <hooper
    :ref="uid"
    :settings="options"
    @slide="onSlide"
  >
    <slot>
      <loading-circle />
    </slot>
    <hooper-navigation
      v-if="loaded > 0"
      slot="hooper-addons"
    />
  </hooper>
</template>

<script>
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { Hooper, Navigation as HooperNavigation } from 'hooper'
import LoadingCircle from '@/components/loading-circle/loading-circle'

@Component({
    components: {
        LoadingCircle,
        Hooper,
        HooperNavigation
    }
})
export default class CardCarousel extends Vue {
  @Prop({
      type: Array,
      default: () => [[0, 1], [516, 2], [768, 3], [1024, 4], [1264, 4], [1400, 6]]
  }) breakpoints

  @Prop({
      type: Number,
      default: -1
  }) total

  @Prop({
      type: Number,
      default: 0
  }) loaded

  @Prop({
      type: Boolean,
      default: () => false
  }) active

  get carousel () {
      return this.$refs[this.uid]
  }

  get delta () {
      return this.carousel.config.itemsToShow * 2
  }

  get brs () {
      const result = {}
      this.breakpoints.forEach(elem => {
          const w = elem[0]
          const s = elem[1]
          result[w] = {
              itemsToShow: s,
              itemsToSlide: s
          }
      })
      return result
  }

  get options () {
      return {
          mouseDrag: false,
          touchDrag: true,
          wheelControl: false,
          keysControl: false,
          shortDrag: false,
          centerMode: false,
          itemsToShow: 4,
          itemsToSlide: 4,
          breakpoints: this.brs
      }
  }

  onSlide (evt) {
      this.currentSlide = evt.currentSlide
      if (this.total > 0 && this.currentSlide + this.delta > this.loaded && this.currentSlide) {
          this.$emit('fetchMore')
      }
  }

  updated () {
      this.carousel.updateWidth()
  }

  beforeMount () {
      this.uid = 'carousel' + Math.random().toString(36).substr(2, 9)
  }

  @Watch('active')
  onChangeActive (n) {
      if (n) {
          this.$nextTick(() => {
              this.carousel.restart()
              this.carousel.currentSlide = 0
              this.carousel.slideTo(0)
          })
      }
  }
}
</script>
