import store from '@/store'
import { RESET, SET_QUERY } from '@/store/test-search/mutation-types'
import { LOAD_FACETS } from '@/store/test-search/action-types'
import { namespacer } from '@/util/namespacer'

export const testTrendfinder = (to, from, next) => {
    if (to.name.indexOf('test-search') !== -1) {
        if (to.query.f || to.query.q || (from.name && from.name.indexOf('test-search') === -1)) {
            store.commit(namespacer('testSearch', RESET))
        }
        if (to.query.f) {
            store
                .dispatch(namespacer('testSearch', LOAD_FACETS), to.query.f.split(','), { root: true })
                .then(() => {
                    if (to.query.q) {
                        store.commit(namespacer('testSearch', SET_QUERY), to.query.q)
                    }
                    next()
                })
        } else {
            if (to.query.q) {
                store.commit(namespacer('testSearch', SET_QUERY), to.query.q)
            }
            next()
        }
    } else {
        next()
    }
}
