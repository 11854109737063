<template>
  <v-list-group
    v-model="state"
    no-action
  >
    <template
      v-slot:activator
    >
      <v-list-item
        class="nav-item"
      >
        <v-list-item-icon>
          <loading-circle
            v-if="padding"
            size="20"
          />
          <strong v-else>{{ currentLocale }}</strong>
        </v-list-item-icon>
        <v-list-item-title>{{ $t('components.navigation.locale.title') }}</v-list-item-title>
      </v-list-item>
    </template>
    <v-list
      class="main-nav-list inner-nav-list"
      dense
    >
      <v-list-item
        v-for="(item, idx) in companyLocales"
        @click="changeLocale(item)"
        :disabled="item === locale"
        :key="idx"
        dense
      >
        <v-list-item-title>
          {{ $t(`components.navigation.locale.locales.${item}`) }}
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-list-group>
</template>

<script>
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import { CHANGE_LOCALE } from '@/store/auth/action-types'
import { GET_COMPANY_LOCALES, GET_LOCALE } from '@/store/auth/getter-types'

import LoadingCircle from '@/components/loading-circle/loading-circle'

const auth = namespace('auth')

@Component({
    components: {
        LoadingCircle
    }
})
export default class LocaleNavigation extends Vue {
  @Prop({
      type: Boolean,
      default: () => false
  }) mini

  @auth.Action(CHANGE_LOCALE) storeChangeLocale
  @auth.Getter(GET_LOCALE) locale
  @auth.Getter(GET_COMPANY_LOCALES) companyLocales

  state = false
  padding = false

  upper (str) {
      return str.toUpperCase()
  }

  get currentLocale () {
      return this.upper(this.locale)
  }

  @Watch('mini')
  onChangeMini (value) {
      if (value) {
          this.state = false
      }
  }

  async changeLocale (locale) {
      this.padding = true
      await this.storeChangeLocale(locale)
      this.padding = false
      this.state = false
  }
}
</script>
