<template>
  <div>
    <v-menu
      v-if="isVisible"
      bottom
      offset-y
      left
      data-test-id="company-micro-trend-context-menu"
    >
      <template v-slot:activator="{ on }">
        <v-btn
          v-on="on"
          @click.prevent
          text
          color="primary"
        >
          <v-icon color="primary">mdi-dots-vertical</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item
          v-if="$can('update', obj) || $can('manage', obj)"
          @click="onEdit"
        >
          <v-list-item-content>
            <v-list-item-title>{{ $t('components.companyMicrotrend.contextMenu.edit') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-if="$can('delete', obj) || $can('manage', obj)"
          @click="onDelete"
        >
          <v-list-item-content>
            <v-list-item-title>{{ $t('components.companyMicrotrend.contextMenu.delete') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
    <confirmation-dialog
      v-model="show"
      :title="$t('components.companyMicrotrend.contextMenu.deletion.title', { title: obj.translations[0].title})"
      :content="$t('components.companyMicrotrend.contextMenu.deletion.content')"
      :notice="$t('components.companyMicrotrend.contextMenu.deletion.notice')"
      :confirm="$t('components.companyMicrotrend.contextMenu.deletion.confirm')"
      :pending="pending"
      @confirmed="deleteCompanyMicrotrend"
    />
  </div>
</template>

<script>
import { Component, Prop, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { FeatureCheckMixin } from '@/mixins'

import { COMPANY_MICROTREND } from '@/model/types'
import { COMPANY_MICROTREND_DELETED } from '@/model/events'
import { RESET_MICROTREND, SET_BACK_URL, SHOW_SNACKBAR } from '@/store/gui/mutation-types'

import deleteGQL from '@/graphql/app/company-microtrend/mutation/delete.gql'

import ConfirmationDialog from '@/components/confirmation-dialog/confirmation-dialog'

const guiStore = namespace('gui')

@Component({
    components: {
        ConfirmationDialog
    },
    mixins: [FeatureCheckMixin]
})
export default class MicrotrendContextMenu extends Vue {
  @Prop({
      type: Object,
      validator: obj => obj.__typename === COMPANY_MICROTREND
  }) obj

  @guiStore.Mutation(SHOW_SNACKBAR) showFeedback

  @guiStore.Mutation(RESET_MICROTREND) closeOverlay

  @guiStore.Mutation(SET_BACK_URL) setBackUrl

  pending = false
  show = false

  get isVisible () {
      return this.hasCompanyMicroTrendFeature &&
      (this.$can('update', this.obj) || this.$can('manage', this.obj) || this.$can('delete', this.obj))
  }

  onEdit () {
      this.setBackUrl(this.$router.history.current)
      this.$router.push({
          name: 'company-microtrend-edit',
          params: { id: this.obj.id }
      })
  }

  onDelete () {
      this.show = true
  }

  async deleteCompanyMicrotrend () {
      try {
          this.pending = true
          await this.$apollo.mutate({
              mutation: deleteGQL,
              variables: {
                  input: {
                      id: this.obj.id
                  }
              }
          })

          await new Promise((resolve) => {
              setTimeout(resolve, 3000)
          })

          this.$root.$emit(COMPANY_MICROTREND_DELETED)
          this.$track('CompanyMicrotrend', 'delete')
          this.showFeedback(this.$t('components.companyMicrotrend.contextMenu.deletion.success'))
          this.show = false
          this.closeOverlay()
      } catch (error) {
          console.log('%cError on delete company-micro-trends: ', 'color: red;', error) // eslint-disable-line no-console
      } finally {
          this.pending = false
      }
  }
}
</script>
