import { Component, Vue } from 'vue-property-decorator'

@Component
export default class ResizeableMixin extends Vue {
    protected windowSize = {
        x: 0,
        y: 0
    }

    onResize () {
        this.windowSize = { x: window.innerWidth, y: window.innerHeight }
    }
}
