import { MACROTRENDS, MEGATRENDS, MICROTRENDS, TRENDING_TOPIC } from '@/model/types'
import { Component, Vue } from 'vue-property-decorator'

@Component
export default class DeeplinkGenerator extends Vue {
    get trendUrl () {
        if (MICROTRENDS.includes(this.node.__typename)) {
            return {
                name: 'trendfinder-microtrends',
                query: { microtrendId: this.node.id }
            }
        }

        if (MACROTRENDS.includes(this.node.__typename)) {
            return {
                name: 'macrotrend-detail',
                params: { macrotrendId: this.node.id }
            }
        }

        if (MEGATRENDS.includes(this.node.__typename)) {
            return {
                name: 'megatrend-detail',
                params: { megatrendId: this.node.id }
            }
        }

        if (TRENDING_TOPIC === this.node.__typename) {
            return {
                name: 'insights-trending-topic-detail',
                params: { id: this.node.id }
            }
        }

        return ''
    }

    get isClickable () {
        return [TRENDING_TOPIC].concat(MACROTRENDS, MICROTRENDS, MEGATRENDS).includes(this.node.__typename || '')
    }

    get url () {
        return this.node.__typename
            ? this.$router.resolve(this.trendUrl).href
            : null
    }
}
