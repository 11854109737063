<template>
  <div class="single-microtrend-loader">
    <slot
      :loading="loading"
      :microtrend="microtrend"
    />
  </div>
</template>

<script>
import { Component, Prop, Vue } from 'vue-property-decorator'
import viewGQL from '@/graphql/app/microtrend/query/view.gql'
import { PAGE_SIZE } from '@/model/constants'
import { LOCALES } from '@/model/locales'

@Component({
    apollo: {
        microtrend: {
            query: viewGQL,
            variables () {
                return {
                    locale: this.locale,
                    id: this.id,
                    page: 1,
                    pageSize: PAGE_SIZE
                }
            },
            skip () {
                return this.id === null
            }
        }
    }
})
export default class MicrotrendSingleLoader extends Vue {
  @Prop({ type: String, required: true }) id
  @Prop({ type: String, validator: (val) => LOCALES.includes(val) }) locale

  microtrend = null

  get loading () {
      return this.$apollo.queries.microtrend.loading
  }
}
</script>
