import { appClient } from '@/plugins/apollo'
import { UseCase, UseCaseContent } from '@/ui/microtrend/use-cases/use-cases.types'
import {
    CreateUseCaseDocument,
    CreateUseCaseMutation,
    CreateUseCaseMutationVariables,
    DeleteUseCaseDocument,
    DeleteUseCaseMutation,
    DeleteUseCaseMutationVariables,
    Locale,
    MicrotrendUseCase,
    UpdateUseCaseDocument,
    UpdateUseCaseMutation,
    UpdateUseCaseMutationVariables,
    UseCasesDocument,
    UseCasesQuery,
    UseCasesQueryVariables
} from '@/graphql/app/types'
import { formattedDate } from '@/util/formatted-date'

export async function createUseCase (locale: Locale, microtrendId: string, title: string, text: string): Promise<UseCase> {
    const result = await appClient.mutate<CreateUseCaseMutation, CreateUseCaseMutationVariables>({
        mutation: CreateUseCaseDocument,
        variables: {
            input: {
                locale,
                microtrendId,
                text,
                title
            }
        }
    })

    const data = result.data

    if (typeof data === 'undefined' || data === null) {
        throw Error('No data')
    }

    return asUseCase(data.microtrendUseCase.createUseCase, locale)
}

export async function updateUseCase (useCaseId: string, content: UseCaseContent, locale: Locale): Promise<UseCase> {
    const result = await appClient.mutate<UpdateUseCaseMutation, UpdateUseCaseMutationVariables>({
        mutation: UpdateUseCaseDocument,
        variables: {
            input: {
                id: useCaseId,
                title: content.title,
                text: content.text
            }
        }
    })

    const data = result.data

    if (typeof data === 'undefined' || data === null) {
        throw Error('No data')
    }

    return asUseCase(data.microtrendUseCase.updateUseCase, locale)
}

export async function deleteUseCase (useCaseId: string): Promise<void> {
    await appClient.mutate<DeleteUseCaseMutation, DeleteUseCaseMutationVariables>({
        mutation: DeleteUseCaseDocument,
        variables: {
            input: {
                id: useCaseId
            }
        }
    })
}

export async function microtrendUseCases (microtrendId: string, locale: Locale): Promise<Array<UseCase>> {
    const result = await appClient.query<UseCasesQuery, UseCasesQueryVariables>({
        query: UseCasesDocument,
        fetchPolicy: 'network-only',
        variables: {
            id: microtrendId,
            locale
        }
    })

    // @ts-ignore
    return result.data.node.useCases.map(useCase => asUseCase(useCase, locale))
}

function asUseCase (node: MicrotrendUseCase, locale: Locale): UseCase {
    return {
        id: node.id || '',
        title: node.title || '',
        text: node.text || '',
        createdOn: formattedDate(locale, node.createdOn),
        author: node.author || '',
        editable: node.editable,
        deletable: node.deletable
    }
}
