import { RADAR } from '@/model/trendradar/modes'
import { AXIS_COMPETENCES, AXIS_MATURITY, AXIS_POTENTIAL } from '@/model/trendradar/chart'

export const initChart = (criterias) => {
    return {
        xAxis: criterias.find(elem => elem.type === AXIS_MATURITY),
        yAxis: criterias.find(elem => elem.type === AXIS_POTENTIAL),
        size: criterias.find(elem => elem.type === AXIS_COMPETENCES) || criterias.find(elem => elem.type === AXIS_POTENTIAL)
    }
}

export const initView = () => ({
    details: {
        show: false,
        mode: {}
    },
    mode: null,
    reloadPostsCnt: 0,
    reloadPostsType: null,
    highlight: [],
    view: RADAR,
    criteriaSet: {},
    chartConfiguration: initChart([], [], []),
    filter: []
})

export const state = initView()
