import tinycolor from 'tinycolor2'
import { MacrotrendInterface } from '@/graphql/app/types'

export default class Macrotrend {
    public readonly id: string
    public readonly title: string
    public readonly color: string
    public readonly parentId: string | null
    public visible: boolean = true

    constructor (id: string, title: string, color: string, parentId: string | null) {
        this.id = id
        this.title = title
        this.color = color
        this.parentId = parentId
    }

    public static fromMacrotrendInterface (macrotrend: MacrotrendInterface) {
        return new Macrotrend(
            macrotrend.id,
            Macrotrend.title(macrotrend),
            Macrotrend.color(macrotrend),
            macrotrend.megatrend?.id || null
        )
    }

    private static title (macrotrend: MacrotrendInterface): string {
        if (macrotrend.translations && macrotrend.translations[0]) {
            return macrotrend.translations[0].title || ''
        }

        return ''
    }

    private static color (macrotrend: MacrotrendInterface): string {
        if (macrotrend.color) {
            return macrotrend.color
        }

        return tinycolor(macrotrend?.megatrend?.color || 'gray').lighten(10).toHexString()
    }
}
