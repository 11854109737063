import * as ACTION from './action-types'
import {
    HIDE_SNACKBAR,
    RESET_MICROTREND,
    SET_LAST_FEATURE_UPDATE,
    SET_MICROTREND,
    SHOW_SNACKBAR
} from './mutation-types'
import * as insightsService from '@/service/insights'
import * as featureService from '@/service/features'
import { track } from '@/plugins/tracker'

export default {
    [ACTION.SHOW_MICROTREND_OVERLAY]: ({ commit }, payload) => {
        commit(SET_MICROTREND, payload)
    },

    [ACTION.HIDE_MICROTREND_OVERLAY]: ({ commit }) => {
        commit(RESET_MICROTREND)
    },

    [ACTION.SHOW_SNACKBAR]: ({ commit }, payload) => {
        commit(SHOW_SNACKBAR, payload)
    },

    [ACTION.HIDE_SNACKBAR]: ({ commit }) => {
        commit(HIDE_SNACKBAR)
    },

    [ACTION.SET_INSIGHTS_UPDATES_SEEN]: async ({ commit }) => {
        await insightsService.setInsightsUpdatesSeen()
    },

    [ACTION.SET_LAST_FEATURE_UPDATE]: async ({ commit }) => {
        try {
            const lastFeatureUpdate = await featureService.setLastFeatureUpdate()

            commit(SET_LAST_FEATURE_UPDATE, lastFeatureUpdate)
        } catch (error) {
            track('User', 'setLastFeatureUpdate', 'failure')
            throw error
        }
    },

    [ACTION.DISABLE_FEATURE_UPDATES]: async () => {
        try {
            await featureService.disableFeatureUpdates()
            track('User', 'disableFeatureUpdatesPermanently')
        } catch (error) {
            track('User', 'disableFeatureUpdatesPermanently', 'failure')
            throw error
        }
    }
}
