<template>
  <component
    :close="close"
    :color="color"
    :icon-style="iconStyle"
    :is="current"
    :label="label"
    :small="small"
    :styles="styles"
    :target="target"
    :to="to"
    :macrotrend="macrotrend"
    class="trend-tag"
    @click="onClick"
    @click:close="onClose"
  />
</template>

<script>
import { Component, Prop, Vue } from 'vue-property-decorator'
import {
    COMPANY_MACROTREND,
    COMPANY_MEGATREND,
    INDUSTRY,
    INNOVATION_TYPE,
    MACROTREND,
    MEGATREND, REDUCED_MACROTREND,
    TRENDCALL
} from '@/model/types'
import tinycolor from 'tinycolor2'

const VALID = [
    INNOVATION_TYPE,
    INDUSTRY,
    COMPANY_MACROTREND,
    COMPANY_MEGATREND,
    MACROTREND,
    REDUCED_MACROTREND,
    MEGATREND
]
const UNLINKED = [TRENDCALL, REDUCED_MACROTREND]

const COLORS = {
    [INDUSTRY]: '#504F62',
    [INNOVATION_TYPE]: '#9A9B9A',
    [TRENDCALL]: '#1620D1'
}

@Component
export default class TrendTag extends Vue {
  @Prop({
      type: Object,
      validate: (node) => VALID.includes(node.__typename)
  }) node

  @Prop({
      type: Boolean,
      default: () => false
  }) close

  @Prop({
      type: Boolean,
      default: () => false
  }) inversed

  @Prop({
      type: String,
      default: () => null
  }) target

  @Prop({
      type: [Object, String],
      default: () => null
  }) to

  @Prop({
      type: Boolean,
      default: () => false
  }) small

  get current () {
      let mode = UNLINKED.includes(this.node.__typename) || this.to === null
          ? 'unlinked'
          : 'linked'

      if (this.node.__typename === REDUCED_MACROTREND || this.node.reduced) {
          mode = 'reduced-trend-tag'
      }

      return () => import(/* webpackChunkName: "[request]" */ `@/components/trend-tag/${mode}.vue`)
  }

  get label () {
      if (this.node) {
          if (this.node.title) {
              return this.node.title
          }

          if (this.node.translations) {
              return this.node.translations
                  .map(t => t?.title)
                  .filter(t => t)[0] || '-/-'
          }
      }

      return '-/-'
  }

  get macrotrend () {
      return [MACROTREND, REDUCED_MACROTREND].includes(this.node.__typename) ? this.node : null
  }

  get color () {
      if (this.node.__typename in COLORS) {
          return COLORS[this.node.__typename]
      }

      if (this.node && this.node.color) {
          return this.node.color
      }

      if (this.node.megatrend && this.node.megatrend.color) {
          return this.lighten(this.node.megatrend.color)
      }

      return 'primary'
  }

  get drawOutline () {
      return tinycolor.readability('#ebebeb', this.color) < 1.38
  }

  get iconStyle () {
      return {
          border: this.drawOutline ? '1px solid #939393' : '',
          'background-color': this.drawOutline ? this.color : '',
          'font-size': this.drawOutline ? '0.90em' : '1.25em',
          'border-radius': '100px'
      }
  }

  get styles () {
      return {
          margin: '0 8px 8px 0 !important',
          'padding-right': '8px',
          'background-color': '#ebebeb',
          color: '#515063'
      }
  }

  lighten (color) {
      return tinycolor(color).lighten(10).toHexString()
  }

  onClick () {
      if (this.to === null) {
          this.$emit('click')
      }
  }

  onClose () {
      this.$emit('click:close')
  }
}
</script>
