
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class UpdateInfo extends Vue {
  @Prop({ type: Boolean, required: true }) admin!: boolean

  onClose () {
      this.$emit('close')
  }

  onShowUpdates () {
      this.$emit('show-updates')
  }
}
