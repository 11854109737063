<template>
  <div class="wysiwyg">
    <div :class="componentClasses">
      <div class="v-input__control">
        <div class="v-input__slot">
          <div class="v-text-field__slot">
            <label
              v-if="label"
              :for="id"
              :class="labelClasses"
            >
              {{ label }}
            </label>
            <vue-editor
              v-model="internal"
              :placeholder="placeholder"
              :editorOptions="options"
              :editorToolbar="toolbar"
              :aria-label="ariaLabel"
              :disabled="disabled"
              :id="id"
              @focus="active = true"
              @blur="active = false"
            />
          </div>
        </div>
        <div class="v-text-field__details">
          <div
            v-if="hasError"
            class="v-messages theme--light error--text" role="alert"
          >
            <div class="v-messages__wrapper">
              <div class="v-messages__message">
                <template
                  v-for="(error, idx) in errorMessages"
                >
                  <div
                    :key="idx"
                  >
                    {{ error }}
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { uid } from '@/util/uid'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { VueEditor } from 'vue2-editor'
import Quill, { lineBreakMatcher } from '@/components/form/editor/config'

@Component({
    components: {
        VueEditor
    }
})
export default class Editor extends Vue {
  @Prop() value

  @Prop({
      type: String,
      default: () => null
  }) label

  @Prop({
      type: String,
      default: () => null
  }) placeholder

  @Prop({
      type: Array,
      default: () => []
  }) errorMessages

  @Prop({
      type: Boolean,
      default: () => null
  }) disabled

  @Prop({
      type: String,
      default: () => ''
  }) ariaLabel

  @Prop({
      type: Array,
      default: () => ([
          ['bold', 'italic', 'underline'],
          [{ list: 'ordered' }, { list: 'bullet' }],
          ['link']
      ])
  }) toolbar

  dirty = false
  active = false
  id = null

  get internal () {
      return this.value
  }

  set internal (value) {
      this.$emit('input', value)
  }

  @Watch('content')
  hasChanged () {
      this.dirty = true
  }

  get hasError () {
      return this.errorMessages.length > 0
  }

  get options () {
      return {
          placeholder: this.placeholder,
          modules: {
              clipboard: {
                  matchers: [['BR', lineBreakMatcher]],
                  matchVisual: false
              },
              keyboard: {
                  bindings: {
                      linebreak: {
                          key: 13,
                          shiftKey: true,
                          handler: function (range) {
                              const currentLeaf = this.quill.getLeaf(range.index)[0]
                              const nextLeaf = this.quill.getLeaf(range.index + 1)[0]
                              this.quill.insertEmbed(range.index, 'break', true, 'user')
                              // Insert a second break if:
                              // At the end of the editor, OR next leaf has a different parent (<p>)
                              if (nextLeaf === null || currentLeaf.parent !== nextLeaf.parent) {
                                  this.quill.insertEmbed(range.index, 'break', true, 'user')
                              }
                              // Now that we've inserted a line break, move the cursor forward
                              this.quill.setSelection(range.index + 1, Quill.sources.SILENT)
                          }
                      }
                  }
              }
          }
      }
  }

  get labelClasses () {
      return [
          'v-label theme--light v-label--active',
          this.hasError ? 'error--text' : this.active ? ' primary--text' : ''
      ]
  }

  get componentClasses () {
      return [
          'v-input-group v-input-group--dirty v-input-group--text-field v-input-group--multi-line',
          this.dirty ? 'v-input--is-dirty' : '',
          this.hasError ? 'v-input-group--error error--text' : ''
      ]
  }

  mounted () {
      this.id = `input-${uid()}`
      this.dirty = false
  }
}
</script>
