<template>
  <v-col class="facet-box-container">
    <v-menu
      v-model="show"
      :close-on-click="true"
      :close-on-content-click="false"
      class="facet-box"
      offset-y
    >
      <template v-slot:activator="{ on }">
        <v-btn
          block
          text
          v-on="on"
        >
          {{ $t(`components.searchAgent.search.facetMenu.title.${facet.title}`) }}
          <to-icon right>{{ show ? 'menu-up' : 'menu-down' }}</to-icon>
        </v-btn>
      </template>
      <v-card
        tile
      >
        <v-card-text>
          <v-text-field
            ref="search"
            v-model="search"
            :label="$t('components.searchAgent.search.facetMenu.search',{ title: $t(`components.searchAgent.search.facetMenu.title.${facet.title}`) })"
            autocomplete="off"
            hide-details
            single-line
            dense
            filled
          />
        </v-card-text>
        <v-list
          :key="debouncedSearch"
          class="facet-entry-list"
          dense
        >
          <template
            v-if="standard"
          >
            <template
              v-for="entry in elements"
            >
              <child
                v-if="entry.visible"
                :key="entry.id"
                :entry="entry"
                :state="state"
                @addChild="onAddChild"
                @removeChild="onRemoveChild"
              />
            </template>
          </template>
          <template
            v-else
          >
            <template
              v-for="parent in elements"
            >
              <parent
                v-if="parent.visible"
                :key="parent.id"
                :parent="parent"
                :state="state"
                @addChild="onAddChild"
                @addParent="onAddParent"
                @removeChild="onRemoveChild"
                @removeParent="onRemoveParent"
              />
            </template>
          </template>
        </v-list>
        <v-card-actions>
          <v-spacer/>
          <v-btn
            color="primary"
            small
            @click.native.stop="show = !show"
          >
            {{ $t('components.searchAgent.search.facetMenu.close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>
  </v-col>
</template>

<script>
import { Component, Prop, Vue } from 'vue-property-decorator'
import { FACET_TYPE_STANDARD, sortFacetMap } from '@/util/Facets'

import ToIcon from '@/components/to-icon/to-icon'
import Child from './facet-child'
import Parent from './facet-parent'

@Component({
    components: {
        Parent,
        Child,
        ToIcon
    }
})
export default class SearchFacetMenu extends Vue {
  @Prop({
      type: Object,
      required: true
  }) facet

  @Prop({
      type: Object,
      default: () => {
      }
  }) state

  show = false
  search = ''

  get standard () {
      return this.facet.type === FACET_TYPE_STANDARD
  }

  get elements () {
      return sortFacetMap(this.facet.search(this.search))
  }

  onAddChild (value) {
      this.$emit('addChild', value)
  }

  onRemoveChild (value) {
      this.$emit('removeChild', value)
  }

  onAddParent (value) {
      this.$emit('addParent', value)
  }

  onRemoveParent (value) {
      this.$emit('removeParent', value)
  }
}
</script>
