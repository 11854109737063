<template>
  <v-snackbar
    v-model="snackbar"
    :color="color"
    :dark="dark"
    :light="!dark"
    :timeout="timeout"
    max-width="480"
    right
    tile
    top
  >
    <v-card
      color="transparent"
      flat
    >
      <v-row>
        <v-col
          class="flex-shrink-0 flex-grow-1"
        >
          <v-list color="transparent" class="py-1">
            <template v-for="(message, idx) in messages">
              <v-list-item
                v-html="message"
                :key="`${idx}-m`"
                dense
                class="px-1 py-0 d-inline-block"
                style="white-space: normal"
              >
              </v-list-item>
            </template>
            <template
              v-for="(target, idx) in targets"
            >
              <v-list-item
                :key="`${idx}-t`"
                :to="target.to"
                dense
                class="px-2 py-0 d-inline-flex align-items-center"
              >
                <v-list-item-title>
                  {{ target.title }}
                </v-list-item-title>
              </v-list-item>
            </template>
          </v-list>
        </v-col>

        <v-col
          class="flex-grow-0 flex-shrink-1 text-right"
        >
          <v-btn
            icon
            small
            @click="snackbar = false"
          >
            <v-icon small>mdi-close</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
  </v-snackbar>
</template>

<script>
import {
    GET_SNACKBAR_MESSAGES,
    GET_SNACKBAR_MODE,
    GET_SNACKBAR_TARGETS,
    GET_SNACKBAR_TIMEOUT,
    IS_SNACKBAR
} from '@/store/gui/getter-types'
import { HIDE_SNACKBAR } from '@/store/gui/mutation-types'
import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

const gui = namespace('gui')

@Component
export default class Snackbar extends Vue {
  @gui.Mutation(HIDE_SNACKBAR) hideSnackbar
  @gui.Getter(IS_SNACKBAR) isSnackbar
  @gui.Getter(GET_SNACKBAR_TIMEOUT) timeout
  @gui.Getter(GET_SNACKBAR_MODE) mode
  @gui.Getter(GET_SNACKBAR_MESSAGES) messages
  @gui.Getter(GET_SNACKBAR_TARGETS) targets

  get snackbar () {
      return this.isSnackbar
  }

  set snackbar (value) {
      this.hideSnackbar()
  }

  get dark () {
      return this.mode !== 'info'
  }

  get color () {
      if (['error', 'primary', 'info'].includes(this.mode)) {
          return this.mode
      }

      return 'primary'
  }
}
</script>
