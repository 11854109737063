<template>
  <v-dialog
    v-model="show"
    persistent
    @keydown.esc="show = false"
  >
    <microtrend-single-loader
      v-if="microtrendId !== null"
      :id="microtrendId"
      :locale="locale"
    >
      <template v-slot="{ loading, microtrend }">
        <loading-circle
          v-if="loading"
          class="pa-10"
          color="white"
        />
        <microtrend-overlay-content
          v-else
          :microtrend="microtrend"
          :locale="locale"
          @close:overlay="onClose"
        />
      </template>
    </microtrend-single-loader>
  </v-dialog>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import { GET_MICROTREND } from '@/store/gui/getter-types'
import { RESET_MICROTREND } from '@/store/gui/mutation-types'
import { GET_LOCALE } from '@/store/auth/getter-types'

import LoadingCircle from '@/components/loading-circle/loading-circle'
import MicrotrendOverlayContent from './overlay-content'
import MicrotrendSingleLoader from '@/components/microtrend/loader/microtrend-single-loader'

const auth = namespace('auth')
const gui = namespace('gui')

@Component({
    components: {
        MicrotrendSingleLoader,
        LoadingCircle,
        MicrotrendOverlayContent
    }
})
export default class MicrotrendOverlay extends Vue {
  @auth.Getter(GET_LOCALE) locale
  @gui.Getter(GET_MICROTREND) microtrendId
  @gui.Mutation(RESET_MICROTREND) hideMicrotrendOverlay

  get show () {
      return this.microtrendId !== null
  }

  set show (value) {
      this.hideMicrotrendOverlay()
  }

  onExit () {
      this.confirmation = false
      this.$v.$reset()
      this.hideMicrotrendOverlay()
  }

  async onSave () {
      await this.submit({
          id: this.microtrend.id,
          macrotrendIds: this.microtrend.macrotrendIds,
          industryIds: this.microtrend.industryIds,
          innovationTypeId: this.microtrend.innovationTypeId
      })
      this.onExit()
  }

  onClose () {
      this.hideMicrotrendOverlay()
  }
}
</script>
