
import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import LoadingCircle from '@/components/loading-circle/loading-circle.vue'
import ToIcon from '@/components/to-icon/to-icon.vue'
import TrendTree from '@/components/trend-tree/trend-tree.vue'

// @ts-ignore
import setPinsGQL from '@/graphql/app/macrotrend/mutation/pin-microtrends.gql'

import { GET_PIN } from '@/store/gui/getter-types'
import { HIDE_ADD_GENERIC, SHOW_SNACKBAR } from '@/store/gui/mutation-types'
import { GET_LOCALE } from '@/store/auth/getter-types'
import { RESET_BULK_SELECTION, ResetBulkSelection } from '@/store/bulk-selection/mutations'
import { CompanyMicrotrend, Locale, MacrotrendInterface, Microtrend, useMicrotrendPinQuery } from '@/graphql/app/types'
import { GetPin, HideAddGeneric, ShowSnackbar } from '@/store/gui/types'
import Macrotrend from '@/model/trends/macrotrend'

const authStore = namespace('auth')
const guiStore = namespace('gui')
const bulkSelectionStore = namespace('bulkSelection')

@Component<PinMicroToMacro>({
    apollo: {
        microtrend: useMicrotrendPinQuery<PinMicroToMacro>({
            fetchPolicy: 'network-only',
            variables () {
                return {
                    id: this.pin.microtrendIds[0],
                    locale: this.locale
                }
            },
            update (data) {
                const microtrend = data.microtrend ? data.microtrend as Partial<Microtrend> | Partial<CompanyMicrotrend> : null
                if (microtrend !== null) {
                    this.macrotrends = microtrend.pins?.map(pin => pin.macrotrend) || []

                    if (this.microtrendsCount === 1) {
                        // These preselected macrotrends are marked as non-editable in trend-tree
                        this.selected = this.macrotrends
                    }
                }
                return data.microtrend
            },
            skip () {
                return this.microtrendsCount === 0
            }
        })
    },
    components: {
        LoadingCircle,
        ToIcon,
        TrendTree
    }
})
export default class PinMicroToMacro extends Vue {
  @authStore.Getter(GET_LOCALE) locale!: Locale
  @guiStore.Getter(GET_PIN) pin!: GetPin
  @guiStore.Mutation(HIDE_ADD_GENERIC) storeHideAddGeneric!: HideAddGeneric
  @guiStore.Mutation(SHOW_SNACKBAR) showSnackbar!: ShowSnackbar
  @bulkSelectionStore.Mutation(RESET_BULK_SELECTION) resetBulkSelection!: ResetBulkSelection

  pending = false
  microtrend: Partial<Microtrend> | Partial<CompanyMicrotrend> | null = null
  macrotrends: Array<MacrotrendInterface> = []
  // TODO: Add preselected macrotrends via separate prop to trend-tree and remove Uniontype
  // keeping type distinction instead of mapping MacrotrendInterface to Macrotrend in apollo update method in order
  // tell them apart in onSuccess handler
  selected: Array<MacrotrendInterface | Macrotrend> = []

  get disabled () {
      return this.trulySelectedMacrotrends.length === 0
  }

  get loading () {
      return this.$apollo.queries.microtrend.loading
  }

  get title () {
      const translations = this.microtrend?.translations

      return translations && translations.length > 0 ? translations[0]?.title : 'n/a'
  }

  get show () {
      return this.microtrendsCount > 0
  }

  set show (value) {
      this.storeHideAddGeneric()
  }

  get microtrendsCount () {
      return this.pin.microtrendIds.length
  }

  async onSubmit () {
      try {
          this.pending = true
          await this.$apollo.mutate({
              mutation: setPinsGQL,
              variables: {
                  input: {
                      microtrendIds: this.pin.microtrendIds,
                      macrotrendIds: this.selected.map(elem => elem.id)
                  }
              }
          })
          this.resetBulkSelection()
          this.onSuccess()
      } catch (e) {
      // eslint-disable-next-line no-console
          console.log('%cError on submit pinMicroToMacro)', 'color: red;', e)
      } finally {
          this.pending = false
      }
  }

  // TODO: Seperate per-selected macrotrends from selected because we do not want to show these in toast
  get trulySelectedMacrotrends (): Array<Macrotrend> {
      return this.selected.filter(macrotrend => 'title' in macrotrend) as Array<Macrotrend>
  }

  onSuccess () {
      this.showSnackbar({
          messages: [this.message()],
          targets: this.trulySelectedMacrotrends.map(macrotrend => ({
              to: {
                  name: 'macrotrend-detail',
                  params: { macrotrendId: macrotrend.id }
              },
              title: macrotrend.title
          }))
      })
      this.$track('Microtrend', 'pin')
      this.show = false
  }

  message (): string {
      if (this.microtrendsCount === 1) {
          return this.$t('components.macrotrend.pinMicroToMacro.success.pin.single', { title: this.title }) as string
      }

      return this.$t('components.macrotrend.pinMicroToMacro.success.pin.bulk') as string
  }
}
