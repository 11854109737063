import { ExplainTrendDocument, ExplainTrendQuery, ExplainTrendQueryVariables, Locale } from '@/graphql/app/types'
import { appClient } from '@/plugins/apollo'

export function useAiTrendExplanation () {
    const explain = async (trendId: string, industry: string, locale: Locale): Promise<string> => {
        const result = await appClient.query<ExplainTrendQuery, ExplainTrendQueryVariables>({
            query: ExplainTrendDocument,
            variables: {
                input: {
                    trendId,
                    industry,
                    locale
                }
            }
        })

        return result.data.ai.explainTrend
    }

    return { explain }
}
