<template>
  <confirmation-dialog
    v-model="show"
    v-if="innovationField && show"
    :title="$t('components.innovationField.deletion.title', { title: innovationField.title })"
    :content="$t('components.innovationField.deletion.content')"
    :confirm="$t('components.innovationField.deletion.confirm')"
    @confirmed="deleteWorkspace"
    :pending="pending"
  />
</template>

<script>
import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { HIDE_ALL_DIALOGS } from '@/store/innovation-field/mutation-types'
import { SHOW_SNACKBAR } from '@/store/gui/mutation-types'

import deleteGQL from '@/graphql/app/innovation-field/mutation/delete.gql'

import ConfirmationDialog from '@/components/confirmation-dialog/confirmation-dialog'
import { GET_DELETE_DATA, SHOW_DELETE_DIALOG } from '@/store/innovation-field/getter-types'

const innovationFieldStore = namespace('innovationField')
const guiStore = namespace('gui')

@Component({
    components: {
        ConfirmationDialog
    }
})
export default class InnovationfieldDelete extends Vue {
  @guiStore.Mutation(SHOW_SNACKBAR) showSnackbar

  @innovationFieldStore.Getter(GET_DELETE_DATA) innovationField
  @innovationFieldStore.Getter(SHOW_DELETE_DIALOG) delete
  @innovationFieldStore.Mutation(HIDE_ALL_DIALOGS) hide

  pending = false

  get show () {
      return this.delete
  }

  set show (value) {
      if (!value) {
          this.hide()
      }
  }

  async deleteWorkspace () {
      try {
          this.pending = true
          await this.$apollo.mutate({
              mutation: deleteGQL,
              variables: {
                  id: this.innovationField.id
              }
          })
          this.$track('InnovationField', 'delete')
          this.showSnackbar(this.$t('components.innovationField.deletion.success'))
          await this.$router.push({ name: 'innovation-fields' })
          this.hide()
      } catch (error) {
      // eslint-disable-next-line no-console
          console.log('%cError on delete innovation field:', 'color: red;', error)
      } finally {
          this.pending = false
      }
  }
}
</script>
