import { appClient } from '@/plugins/apollo'
// @ts-ignore
import pollGQL from '@/graphql/app/export/query/poll.gql'
import { Export, ExportStatus } from '@/graphql/app/types'

interface PollExportUrl {
  start: () => Promise<string>
  stop: () => void
}

export function pollExportUrl (id: string): PollExportUrl {
    const poll = appClient.watchQuery<{ node: Export }>({
        variables: {
            id
        },
        query: pollGQL,
        pollInterval: 1000
    })

    const start = async (): Promise<string> => new Promise((resolve, reject) => {
        poll.subscribe(v => {
            if (v.data.node?.status === ExportStatus.Ready && v.data.node?.url) {
                resolve(v.data.node.url)
                poll.stopPolling()
            }

            if (!v.data.node || v.data.node.status === ExportStatus.Error) {
                reject(new Error('Export failed'))
                poll.stopPolling()
            }
        })
    })

    const stop = () => { poll.stopPolling() }

    return {
        start,
        stop
    }
}
