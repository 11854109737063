
import { Component, Mixins, Prop } from 'vue-property-decorator'
import { COMPANY_MICROTREND, MICROTRENDS, REDUCED_MICROTREND } from '@/model/types'

import AddButton from '@/components/microtrend/add-button/add-button.vue'
import MicrotrendGalleryCard from '@/ui/microtrend/microtrend-gallery-card/microtrend-gallery-card.vue'
import { DateMixin, TranslationMixin } from '@/mixins'
import {
    GalleryMicrotrend,
    GalleryMicrotrendAuthor,
    MicrotrendGalleryCardTextContent
} from '@/ui/microtrend/microtrend-gallery-card/microtrend-gallery-card.types'
import { CompanyMicrotrend, Microtrend } from '@/graphql/app/types'
import TmTooltip from '@/ui/tm-tooltip/tm-tooltip.vue'

@Component({
    components: {
        TmTooltip,
        MicrotrendGalleryCard,
        AddButton
    }
})
export default class MicrotrendSwitch extends Mixins(DateMixin, TranslationMixin) {
  @Prop({
      type: Object,
      validator: (obj: any) => MICROTRENDS.includes(obj.__typename)
  }) content!: Partial<Microtrend | CompanyMicrotrend>

  @Prop({ type: Boolean, default: () => false }) isNew!: boolean

  get currentRoute () {
      return {
          ...this.$route,
          query: {
              ...this.$route.query,
              microtrendId: this.content.id
          }
      }
  }

  get companyTrend (): boolean {
      return this.content.__typename === COMPANY_MICROTREND
  }

  get reducedTrend (): boolean {
      return this.content.__typename === REDUCED_MICROTREND
  }

  get microtrend (): GalleryMicrotrend {
      return {
          image: (this.content.images ?? [])[0] || '',
          date: this.date(this.content.identifiedAt),
          title: this.retrieveTranslation(this.content.translations).title,
          description: this.retrieveTranslation(this.content.translations).description
      }
  }

  get author (): GalleryMicrotrendAuthor | null {
      const author = 'author' in this.content
          ? (this.content.author || null)
          : null

      if (author === null) {
          return null
      }

      return {
          image: author.avatar,
          name: `${author.firstName} ${author.lastName}`
      }
  }

  get textContent (): MicrotrendGalleryCardTextContent {
      return {
          noTranslation: this.$t('components.microtrend.card.noTranslation').toString(),
          newChip: this.$t('components.microtrend.card.new').toString()
      }
  }

  get hasUseCases (): boolean {
      return (this.content.useCases || []).length > 0
  }

  onClick (): void {
      this.$emit('click')
  }

  onRemoveClick (): void {
      this.$emit('remove', this.content)
  }
}
