import * as GETTER from './getter-types'

export default {
    [GETTER.GET_LOADING]: (state) => state.loading,

    [GETTER.GET_PENDING]: (state) => state.pending,

    [GETTER.GET_PARTICIPATION]: (state) => state.participation,

    [GETTER.GET_EVALUATION_TREE]: (state) => {
        const tree = {}

        state.participation.evaluations.forEach(evaluation => {
            if (!(evaluation.macrotrend.megatrend.id in tree)) {
                tree[evaluation.macrotrend.megatrend.id] = {
                    megatrend: {
                        ...evaluation.macrotrend.megatrend
                    },
                    opened: false,
                    evaluations: []
                }
            }

            tree[evaluation.macrotrend.megatrend.id].evaluations.push(evaluation)
        })

        const parents = Object.values(tree).sort((a, b) => a.megatrend.translations[0].title.localeCompare(b.megatrend.translations[0].title))
        parents.forEach(parent => {
            parent.evaluations.sort((a, b) => a.macrotrend.translations[0].title.localeCompare(b.macrotrend.translations[0].title))
        })

        return parents
    },

    [GETTER.GET_EVALUATION_LIST]: (state) => {
        return state.participation.evaluations.concat().sort((a, b) => {
            const val = a.macrotrend.megatrend.translations[0].title.localeCompare(b.macrotrend.megatrend.translations[0].title)
            if (val === 0) {
                return a.macrotrend.translations[0].title.localeCompare(b.macrotrend.translations[0].title)
            }

            return val
        })
    },

    [GETTER.PROGRESS]: (state) => {
        if (state.participation.evaluations === null || state.participation.evaluations.length === 0) {
            return 0
        }

        const done = state
            .participation
            .evaluations
            .map(elem => elem.evaluation === null ? 0 : 1)
            .reduce((a, b) => a + b)

        return done / state.participation.evaluations.length
    },

    [GETTER.PROGRESS_FORMATTED]: (state, getters) => (locale) => {
        const formatter = new Intl.NumberFormat(locale, {
            style: 'percent',
            // minimumFractionDigits: 1,
            maximumFractionDigits: 1
        })

        return formatter.format(getters[GETTER.PROGRESS])
    },

    [GETTER.GET_CRITERIA_SET]: (state) => state.participation.criteriaSet,

    [GETTER.SHOW_PARTICIPATION_COMPLETED_POPUP]: (state) => state.participationCompleted
}
