<template>
  <confirmation-dialog
    v-model="show"
    v-if="show"
    :title="$t('components.workspace.leave.title', { title: workspace.title })"
    :content="$t('components.workspace.leave.content')"
    :confirm="$t('components.workspace.leave.confirm')"
    @confirmed="onConfirmed"
    :pending="pending"
  />
</template>

<script>
import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import { HIDE_ALL_DIALOGS } from '@/store/workspace/mutation-types'
import { SHOW_SNACKBAR } from '@/store/gui/mutation-types'
import { GET_CURRENT_WORKSPACE, IS_LEAVE } from '@/store/workspace/getter-types'

import ConfirmationDialog from '@/components/confirmation-dialog/confirmation-dialog'

import leaveGQL from '@/graphql/app/workspace/mutation/leave.gql'

const guiStore = namespace('gui')
const workspaceStore = namespace('workspace')

@Component({
    components: {
        ConfirmationDialog
    }
})
export default class WorkspaceLeaveDialog extends Vue {
  @guiStore.Mutation(SHOW_SNACKBAR) showSnackbar

  @workspaceStore.Getter(IS_LEAVE) leave
  @workspaceStore.Getter(GET_CURRENT_WORKSPACE) workspace
  @workspaceStore.Mutation(HIDE_ALL_DIALOGS) hide

  pending = false

  get show () {
      return this.leave && this.workspace
  }

  set show (value) {
      if (!value) {
          this.hide()
      }
  }

  async onConfirmed () {
      try {
          this.pending = true
          await this.$apollo.mutate({
              mutation: leaveGQL,
              variables: {
                  id: this.workspace.id
              }
          })
          this.$track('Workspace', 'leave')
          this.showSnackbar(this.$t('components.workspace.leave.success'))
          this.hide()
      } catch (error) {
      // eslint-disable-next-line no-console
          console.log('%cError on leave workspace:', 'color: red;', error)
      } finally {
          this.pending = false
      }
  }
}
</script>
