import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { GET_LOCALE } from '@/store/auth/getter-types'

const auth = namespace('auth')

@Component
export default class TranslatableMixin extends Vue {
  @auth.Getter(GET_LOCALE) locale

  translate (content, what) {
      const container = content.find(elem => elem.locale === this.locale)
      return container && container[what]
          ? container[what]
          : content.length > 0
              ? content[0][what]
              : ''
  }
}
