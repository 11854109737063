<template>
  <component
    :is="current"
    :microtrend="microtrend"
    :pending="pending"
    :mode="mode"
    @change-mode="onChangeMode"
    @submit="onSubmit"
    v-model="internal"
  />
</template>

<script>
import { Component, Prop, Vue } from 'vue-property-decorator'
import { MICROTRENDS } from '@/model/types'
import { EDIT, LIST } from '@/model/views/modes'

import Edit from '@/components/microtrend/overlay/innovation-types/edit'
import View from '@/components/microtrend/overlay/innovation-types/view'

@Component
export default class IndustriesWrapper extends Vue {
  @Prop({
      type: Object,
      validator: obj => MICROTRENDS.includes(obj.__typename),
      required: true
  }) microtrend

  @Prop({
      type: Boolean,
      default: () => false
  }) pending

  @Prop({ type: Array }) value

  mode = LIST

  get current () {
      return this.components[this.mode]
  }

  get internal () {
      return this.value
  }

  set internal (value) {
      this.$emit('input', value)
  }

  onSubmit () {
      this.$emit('submit')
  }

  onChangeMode (value) {
      this.mode = value
  }

  created () {
      this.mode = LIST
      this.components = {
          [LIST]: View,
          [EDIT]: Edit
      }
  }
}
</script>
