
import { Component, Prop, Vue } from 'vue-property-decorator'
import {
    IntroducedTrend,
    MergedTrend,
    ReplacedTrend,
    RevisedTrend,
    UPDATE_TYPE,
    UpdateTrend
} from '@/model/universe-update/types'
import {
    IntroducedMacrotrendsQuery, IntroducedMegatrendsQuery,
    Locale,
    MergedMacrotrendsQuery,
    ReplacedMacrotrendsQuery,
    RevisedMacrotrendsQuery, RevisedMegatrendsQuery,
    useIntroducedMacrotrendsQuery, useIntroducedMegatrendsQuery,
    useMergedMacrotrendsQuery,
    useReplacedMacrotrendsQuery,
    useRevisedMacrotrendsQuery, useRevisedMegatrendsQuery
} from '@/graphql/app/types'

@Component({
    apollo: {
        introducedMegatrends: useIntroducedMegatrendsQuery<TrendUpdateTrendLoader>({
            update: (data: IntroducedMegatrendsQuery) => data.introducedMegatrends.map((item): IntroducedTrend => ({
                title: item.translations![0]!.title || '',
                image: item.image || '',
                updateInfo: item.translations![0]!.updateInfo || '',
                type: UPDATE_TYPE.INTRODUCED_MEGATREND
            })),
            variables () {
                return {
                    locale: this.locale
                }
            }
        }),
        revisedMegatrends: useRevisedMegatrendsQuery<TrendUpdateTrendLoader>({
            update: (data: RevisedMegatrendsQuery) => data.revisedMegatrends.map((item): RevisedTrend => ({
                title: item.translations![0]!.title || '',
                image: item.image || '',
                updateInfo: item.translations![0]!.updateInfo || '',
                type: UPDATE_TYPE.REVISED_MEGATREND
            })),
            variables () {
                return {
                    locale: this.locale
                }
            }
        }),
        introducedTrends: useIntroducedMacrotrendsQuery<TrendUpdateTrendLoader>({
            update: (data: IntroducedMacrotrendsQuery) => data.introducedMacrotrends.map((item): IntroducedTrend => ({
                title: item.translations![0]!.title || '',
                image: item.image || '',
                updateInfo: item.translations![0]!.updateInfo || '',
                type: UPDATE_TYPE.INTRODUCED
            })),
            variables () {
                return {
                    locale: this.locale
                }
            }
        }),
        revisedTrends: useRevisedMacrotrendsQuery<TrendUpdateTrendLoader>({
            update: (data: RevisedMacrotrendsQuery) => data.revisedMacrotrends.map((item): RevisedTrend => ({
                title: item.translations![0]!.title || '',
                image: item.image || '',
                updateInfo: item.translations![0]!.updateInfo || '',
                type: UPDATE_TYPE.REVISED
            })),
            variables () {
                return {
                    locale: this.locale
                }
            }
        }),
        replacedTrends: useReplacedMacrotrendsQuery<TrendUpdateTrendLoader>({
            update: (data: ReplacedMacrotrendsQuery) => data.replacedMacrotrends.map((item): ReplacedTrend => ({
                successor: {
                    title: item.successor.translations![0]!.title || '',
                    image: item.successor.image || '',
                    updateInfo: item.successor.translations![0]!.updateInfo || ''
                },
                predecessor: {
                    title: item.predecessor.translations![0]!.title || '',
                    image: item.predecessor.image || ''
                },
                type: UPDATE_TYPE.REPLACED
            })),
            variables () {
                return {
                    locale: this.locale
                }
            }
        }),
        mergedTrends: useMergedMacrotrendsQuery<TrendUpdateTrendLoader>({
            update: (data: MergedMacrotrendsQuery) => data.mergedMacrotrends.map((item): MergedTrend => ({
                successor: {
                    title: item.successor.translations![0]!.title || '',
                    image: item.successor.image || '',
                    updateInfo: item.successor.translations![0]!.updateInfo || ''
                },
                predecessor: {
                    title: item.predecessor.translations![0]!.title || '',
                    image: item.predecessor.image || ''
                },
                type: UPDATE_TYPE.MERGED
            })),
            variables () {
                return {
                    locale: this.locale
                }
            }
        })
    }
})
export default class TrendUpdateTrendLoader extends Vue {
  @Prop({ type: String }) locale!: Locale

  private introducedMegatrends: IntroducedTrend[] = []
  private revisedMegatrends: RevisedTrend[] = []
  private introducedTrends: IntroducedTrend[] = []
  private revisedTrends: RevisedTrend[] = []
  private replacedTrends: ReplacedTrend[] = []
  private mergedTrends: MergedTrend[] = []

  get trends (): Array<UpdateTrend> {
      return [
          ...this.introducedMegatrends,
          ...this.revisedMegatrends,
          ...this.introducedTrends,
          ...this.revisedTrends,
          ...this.replacedTrends,
          ...this.mergedTrends
      ]
  }

  get loading (): boolean {
      return this.$apollo.queries.introducedMegatrends.loading ||
      this.$apollo.queries.revisedMegatrends.loading ||
      this.$apollo.queries.introducedTrends.loading ||
      this.$apollo.queries.revisedTrends.loading ||
      this.$apollo.queries.replacedTrends.loading ||
      this.$apollo.queries.mergedTrends.loading
  }
}
