<template>
  <microtrend-default-handler
    :content="content"
    :is-new="isNew"
  >
    <template v-slot:action>
      <slot name="action"/>
    </template>
    <template v-slot:title-action>
      <slot name="title-action"/>
    </template>
  </microtrend-default-handler>
</template>

<script>
import { Component, Prop, Vue } from 'vue-property-decorator'
import { MICROTRENDS } from '@/model/types'

import MicrotrendDefaultHandler from '@/components/microtrend/card/microtrend-default-handler'

@Component({
    components: {
        MicrotrendDefaultHandler
    }
})
export default class MicrotrendCard extends Vue {
  @Prop({
      type: Object,
      validator: obj => MICROTRENDS.includes(obj.__typename)
  }) content

  @Prop({
      type: Boolean,
      default: () => false
  }) isNew
}
</script>
