
import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class' // @ts-ignore
import microtrendsGQL from '@/graphql/app/microtrend/query/add-to-workspaces.gql' // @ts-ignore
import macrotrendsGQL from '@/graphql/app/macrotrend/query/add-to-workspaces.gql' // @ts-ignore
import addGQL from '@/graphql/app/workspace/mutation/add.gql'

import { MICROTREND } from '@/model/trends/types'
import { HIDE_ADD_GENERIC, SHOW_SNACKBAR } from '@/store/gui/mutation-types'
import { GET_ADD_TRENDS_TO_WORKSPACE } from '@/store/gui/getter-types'
import { GET_LOCALE } from '@/store/auth/getter-types'

import LoadingCircle from '@/components/loading-circle/loading-circle.vue'
import ToIcon from '@/components/to-icon/to-icon.vue'
import { ASC, DESC } from '@/model/workspace/sort'
import SortButton from '@/components/workspace/add/sort-button.vue'
import {
    AddNodesToWorkspacesMutation,
    CompanyMacrotrend,
    CompanyMicrotrend,
    createWorkspaceMutation,
    Locale,
    Macrotrend,
    Microtrend,
    PaginatedWorkspaces,
    Workspace
} from '@/graphql/app/types'
import { GetAddTrendsToWorkspace, HideAddGeneric, ShowSnackbar } from '@/store/gui/types'
import { RESET_BULK_SELECTION, ResetBulkSelection } from '@/store/bulk-selection/mutations'

const authStore = namespace('auth')
const guiStore = namespace('gui')
const bulkSelectionStore = namespace('bulkSelection')

type Add = {
  trend: CompanyMicrotrend | Microtrend | Macrotrend | CompanyMacrotrend
  workspaces: PaginatedWorkspaces
}
@Component<AddTrendToWorkspace>({
    apollo: {
        add: {
            fetchPolicy: 'network-only',
            query () {
                return this.firstTrend.type === MICROTREND
                    ? microtrendsGQL
                    : macrotrendsGQL
            },
            variables () {
                return {
                    id: this.firstTrend.id,
                    locale: this.locale,
                    sort: {
                        field: this.sort.field,
                        direction: this.sort[this.sort.field]
                    }
                }
            },
            update (data) {
                return data
            },
            skip () {
                return this.firstTrend.id === null
            }
        }
    },
    components: {
        SortButton,
        LoadingCircle,
        ToIcon
    }
})
export default class AddTrendToWorkspace extends Vue {
  @authStore.Getter(GET_LOCALE) locale!: Locale

  @guiStore.Getter(GET_ADD_TRENDS_TO_WORKSPACE) trends!: GetAddTrendsToWorkspace

  @guiStore.Mutation(HIDE_ADD_GENERIC) hideAddGeneric!: HideAddGeneric

  @guiStore.Mutation(SHOW_SNACKBAR) showSnackbar!: ShowSnackbar

  @bulkSelectionStore.Mutation(RESET_BULK_SELECTION) resetBulkSelection!: ResetBulkSelection

  add: Add | null = null
  pending = false
  selected: Array<string> = []
  newWorkspaceTitle = ''

  sort: { [key: string]: string } = {
      field: 'createdAt',
      createdAt: DESC,
      title: DESC
  }

  get workspaces () {
      return this.add?.workspaces.nodes || []
  }

  get disabled () {
      return this.pending || (this.selected.length === 0 && this.newWorkspaceTitle === '')
  }

  get show () {
      return this.firstTrend.id !== null
  }

  set show (value) {
      this.sort.field = 'createdAt'
      this.sort.createdAt = DESC
      this.hide()
  }

  get firstTrend () {
      return this.trends[0]
  }

  get loading () {
      return this.$apollo.loading
  }

  onClickSort (field: string) {
      this.sort.field = field
      this.sort[field] = this.sort[field] === ASC ? DESC : ASC
  }

  hide () {
      this.pending = false
      this.add = null
      this.selected = []
      this.hideAddGeneric()
  }

  async onSubmit () {
      try {
          this.pending = true
          await this.createAndAddWorkspace()
          const response = await this.$apollo.mutate({
              mutation: addGQL,
              variables: {
                  nodeIds: this.trends.map(trend => trend.id),
                  workspaceIds: this.selected
              }
          })

          this.handleSuccess(response.data)
      } catch (error) {
      // eslint-disable-next-line no-console
          console.log('%cError on add trend(s) to workspace:', 'color: red;', error)
      } finally {
          this.pending = false
      }
  }

  private async createAndAddWorkspace () {
      if (this.newWorkspaceTitle.length === 0) {
          return
      }

      const { data } = await createWorkspaceMutation(
          this,
          { variables: { input: { title: this.newWorkspaceTitle } } }
      )

      if (data?.createWorkspace?.id) {
          this.selected.push(data?.createWorkspace?.id)
      }
  }

  handleSuccess (data: AddNodesToWorkspacesMutation) {
      this.$track('Workspace', 'add', this.firstTrend.type)
      this.showSnackbar({
          mode: 'feedback',
          messages: this.successMessages(),
          targets: data.addNodesToWorkspaces.map(elem => ({
              to: {
                  name: 'workspace-detail',
                  params: { workspaceId: elem.id }
              },
              title: elem.title || 'n/a'
          }))
      })
      this.resetBulkSelection()
      this.newWorkspaceTitle = ''
      this.hide()
  }

  successMessages (): Array<string> {
      const count = this.trendCount
      const title = this.add?.trend?.translations![0]?.title

      const message = count === 1
          ? this.$t('components.workspace.addTrend.success.single', { title }) as string
          : this.$t('components.workspace.addTrend.success.bulk') as string

      return [message]
  }

  get trendCount () {
      return this.trends.length
  }

  shouldBeDisabled (ws: Workspace) {
      // For bulk selection all Workspaces should be selectable
      if (this.trendCount > 1) {
          return false
      }

      return ws.containsTrend
  }
}
