export const MICROTREND = 'microtrend'
export const MACROTREND = 'macrotrend'
export const TRENDRADAR = 'trendradar'
export const WORKSPACE = 'workspace'
export const INNOVATION_FIELD = 'innovationField'
export const INNOVATION_PROJECT = 'innovationProject'

export const VALID_TYPES = [
    MICROTREND,
    MACROTREND,
    TRENDRADAR,
    WORKSPACE,
    INNOVATION_FIELD,
    INNOVATION_PROJECT
] as const

export type ExportType = typeof VALID_TYPES[number]
