import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { LOGOUT } from '@/store/auth/action-types'
import { SET } from '@/store/flag/mutation-types'
import { HAS_SEEN_UNIVERSE_DIALOG, UI } from '@/model/flags'

const authStore = namespace('auth')
const flagStore = namespace('flag')

@Component
export default class LogoutMixin extends Vue {
  @authStore.Action(LOGOUT) logout
  @flagStore.Mutation(SET) setFlag

  async handleLogout () {
      await this.logout()
      this.setFlag({ type: UI, name: HAS_SEEN_UNIVERSE_DIALOG, value: false })
      await this.$router.push({ name: 'login' })
  }
}
