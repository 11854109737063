import { DEFAULT_LOCALE } from '@/plugins/i18n'

export const initState = (locale = DEFAULT_LOCALE) => ({
    pending: false,
    rules: [],
    locale: locale,
    tokenExpirationDate: null,
    trackingId: null,
    redirectHash: null,
    user: {
        id: null,
        company: {
            locales: [],
            features: [],
            testAccount: true,
            exportTemplates: [],
            automaticUniverseUpdate: false,
            publicInnovationFields: false
        },
        email: null,
        firstName: null,
        lastName: null,
        avatar: null,
        roles: [],
        locale: DEFAULT_LOCALE,
        enabled: false,
        lastLogin: null,
        showIntroductionVideo: false,
        insightsUpdates: 0,
        adminActsAsUser: false
    }
})
