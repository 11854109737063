<template>
  <v-list-group
    v-model="state"
    no-action
  >
    <template v-slot:activator>
      <v-list-item
        class="nav-item"
        dense
      >
        <v-list-item-icon>
          <v-icon
            color="primary"
            size="24px"
          >
            mdi-plus-circle
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            {{ $t('components.navigation.newMenu.action') }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>

    <v-list
      class="main-nav-list inner-nav-list"
      dense
    >
      <v-list-item
        v-if="$can('create', TYPE.COMPANY_MICROTREND)"
        @click="createMicrotrend"
      >
        <v-list-item-content>
          <v-list-item-title>{{ t('microtrend') }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item
        v-if="$can('create', TYPE.COMPANY_MACROTREND)"
        :to="{ name: 'company-macrotrend-create' }"
      >
        <v-list-item-content>
          <v-list-item-title>{{ t('macrotrend') }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item
        v-if="$can('create', TYPE.COMPANY_MEGATREND)"
        :to="{ name: 'company-megatrend-create' }"
      >
        <v-list-item-content>
          <v-list-item-title>{{ t('megatrend') }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <trendradar-create-dialog
        v-if="$can('create', TYPE.TRENDRADAR_DRAFT)"
        :locale="locale"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-list-item
            v-on="on"
            v-bind="attrs"
          >
            <v-list-item-content>
              <v-list-item-title>{{ t('trendradar') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </trendradar-create-dialog>

      <v-list-item
        v-if="$can('create', TYPE.INNOVATION_FIELD)"
        :to="{ name: 'innovation-field-create' }"
      >
        <v-list-item-content>
          <v-list-item-title>{{ t('innovationField') }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item
        v-if="$can('create', TYPE.INNOVATION_PROJECT)"
        :to="{ name: 'innovation-project-create' }"
      >
        <v-list-item-content>
          <v-list-item-title>{{ t('innovationProject') }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item
        v-if="$can('create', TYPE.WORKSPACE)"
        @click="showCreateWorkspace"
      >
        <v-list-item-content>
          <v-list-item-title>{{ t('workspace') }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

    </v-list>
  </v-list-group>
</template>

<script>
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { SET_BACK_URL } from '@/store/gui/mutation-types'
import { OPEN_SCRAPER } from '@/store/microtrend/action-types'
import { SHOW_EDIT_DIALOG } from '@/store/workspace/mutation-types'
import * as OBJECT_TYPE from '@/model/types'

import ToIcon from '@/components/to-icon/to-icon'
import TrendradarCreateDialog from '@/components/trendradar/create/index.vue'
import { GET_LOCALE, ROLES } from '@/store/auth/getter-types'

const authStore = namespace('auth')
const guiStore = namespace('gui')
const microtrendStore = namespace('microtrend')
const workspaceStore = namespace('workspace')

@Component({
    components: {
        ToIcon,
        TrendradarCreateDialog
    }
})
export default class CreateNavigation extends Vue {
  @Prop({
      type: Boolean,
      default: () => false
  }) mini

  state = false

  @authStore.Getter(GET_LOCALE) locale
  @authStore.Getter(ROLES) roles
  @guiStore.Mutation(SET_BACK_URL) setBackUrl
  @microtrendStore.Action(OPEN_SCRAPER) openScraper
  @workspaceStore.Mutation(SHOW_EDIT_DIALOG) showCreateWorkspaceMutation

  TYPE = OBJECT_TYPE

  showCreateWorkspace () {
      this.showCreateWorkspaceMutation()
  }

  createMicrotrend () {
      this.setBackUrl(this.$router.history.current)
      this.openScraper()
  }

  t (token) {
      const prefix = 'components.navigation.newMenu'
      return this.$t(`${prefix}.${token}`).toString()
  }

  @Watch('mini')
  onChangeMini (value) {
      if (value) {
          this.state = false
      }
  }

  @Watch('$route.name')
  onRouteChange () {
      this.state = false
  }
}
</script>
