import * as MUTATION from './mutation-types'

export default {
    [MUTATION.SET_SCRAPER]: (state, payload) => {
        state.scraper = payload
    },
    [MUTATION.SET_SCRAPED_INFORMATION]: (state, payload) => {
        state.scrapedInformation = payload
    }
}
