import { Component, Vue } from 'vue-property-decorator'

@Component
export default class FilterableMixin extends Vue {
    filterOptions = {}

    filterValues = {}

    pagination = {
        sortBy: [],
        sortDesc: [],
        rowsPerPage: 1
    }

    filterMap = {}

    changeSort (column) {
        if (this.pagination.sortBy[0] === column) {
            this.pagination.sortDesc[0] = !this.pagination.sortDesc[0]
        } else {
            this.pagination.sortBy[0] = column
            this.pagination.sortDesc[0] = false
        }
    }

    isFilterable (idx) {
        return this.filterOptions[idx] && this.filterOptions[idx].length > 0
    }

    filterMegatrends (node, values) {
        return values.includes(node.megatrend.id)
    }

    filterAuthors (node, values) {
        return node.author && node.author.id
            ? values.includes(node.author.id)
            : false
    }

    filterActive (node, values) {
        return values.includes(node.active)
    }
}
