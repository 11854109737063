const state = {
    currentWorkspace: null,
    delete: false,
    leave: false,
    removeNode: false,
    edit: false,
    copy: false,
    sort: {
        macrotrend: {
            field: 'createdAt',
            direction: 'desc'
        },
        microtrend: {
            field: 'createdAt',
            direction: 'desc'
        }
    },
    xlsxExportUrl: null
}
export default state
