<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <h3>{{ $t('components.searchAgent.search.title') }}</h3>
        <query
          :label="$t('components.searchAgent.search.label')"
          v-model="internal.query"
          :ad-hoc="adHoc"
          :loading="loading"
        />
      </v-col>

      <template v-if="availableFacets">
        <v-col
          v-for="(facet, idx) in availableFacets"
          cols="6"
          :key="idx"
        >
          <facet-menu
            :facet="facet"
            :state="value.facetState"
            @addChild="onAddChild"
            @removeChild="onRemoveChild"
            @addParent="onAddParent"
            @removeParent="onRemoveParent"
          />
        </v-col>
      </template>
    </v-row>
    <v-row
      v-if="errors.length > 0"
      :class="classes"
    >
      <v-col
        cols="12"
        class="input-group__details"
      >
        <div
          v-for="(error, idx) in errors"
          :key="idx"
          class="input-group__messages input-group__error"
        >
          {{ error }}
        </div>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        v-if="value.query || Object.keys(value.facetState).length > 0"
        cols="12"
        class="trend-tags"
      >
        <search-tag
          class="trend-tag"
          v-model="value.query"
          v-if="value.query"
        />
        <facet-tag
          v-for="tag in value.facetState"
          :key="tag.id"
          :tag="tag"
          class="trend-tag"
          @removeChild="onRemoveChild"
          @removeParent="onRemoveParent"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { Component, Prop, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import { FacetFactory } from '@/util/Facets'
import { empty } from '@/model/search-agent/initial'

import facetsGQL from '@/graphql/app/trendfinder/query/facets.gql'

import FacetMenu from '@/components/search-agent/search/facet-menu'
import FacetTag from '@/components/search-agent/search/facet-tag'
import Query from '@/components/search-agent/search/query'
import SearchTag from '@/components/search-agent/search/search-tag'

import { GET_LOCALE } from '@/store/auth/getter-types'
import { SHOW_EDIT } from '@/store/search-agent/getter-types'

const authStore = namespace('auth')
const searchAgentStore = namespace('searchAgent')

@Component({
    apollo: {
        availableFacets: {
            fetchPolicy: 'network-only',
            query: facetsGQL,
            variables () {
                return {
                    query: '',
                    locale: this.locale
                }
            },
            update (data) {
                return FacetFactory.create(data.search.microtrends.facets)
            },
            skip () {
                return !this.showSearchAgentEditDialog
            }
        }
    },
    components: {
        FacetMenu,
        FacetTag,
        Query,
        SearchTag
    }
})
export default class SearchAgentSearch extends Vue {
  @Prop({
      type: String,
      default: () => null
  }) label

  @Prop({
      type: Object,
      default: () => empty()
  }) value

  @Prop({
      type: Object,
      default: () => null
  }) validation

  @Prop({
      type: Boolean,
      default: () => false
  }) adHoc

  @authStore.Getter(GET_LOCALE) locale
  @searchAgentStore.Getter(SHOW_EDIT) showSearchAgentEditDialog

  availableFacets = null

  get internal () {
      return this.value
  }

  set internal (value) {
      this.$emit('input', value)
  }

  onAddChild (value) {
      const updatedFacetState = {
          ...this.internal.facetState
      }
      updatedFacetState[value.id] = value

      this.internal = {
          ...this.internal,
          facetState: updatedFacetState
      }
  }

  onRemoveChild (value) {
      const updatedFacetState = {
          ...this.value.facetState
      }
      delete updatedFacetState[value.id]

      this.internal = {
          ...this.internal,
          facetState: updatedFacetState
      }
  }

  onAddParent (value) {
      const updatedFacetState = {
          ...this.value.facetState
      }

      updatedFacetState[value.id] = value

      for (const child of Object.values(value.children)) {
          updatedFacetState[child.id] = child
      }

      this.internal = {
          ...this.internal,
          facetState: updatedFacetState
      }
  }

  onRemoveParent (value) {
      const updatedFacetState = {
          ...this.value.facetState
      }
      delete updatedFacetState[value.id]

      if (value.children) {
          Object.values(value.children).forEach(child => {
              delete updatedFacetState[child.id]
          })
      }

      this.internal = {
          ...this.internal,
          facetState: updatedFacetState
      }
  }

  get errors () {
      const errors = []
      if (!this.validation || !this.validation.$dirty) {
          return errors
      }

      this.validation.$dirty && !this.validation.searchValidator && errors.push(this.$t('components.searchAgent.search.create.error.search'))

      return errors
  }

  get classes () {
      const classes = 'input-group input-group--dirty input-group--text-field input-group--multi-line primary--text'
      return this.errors.length > 0 ? classes + ' input-group--error error--text' : classes
  }

  get loading () {
      return this.$apollo.queries.availableFacets.loading
  }
}
</script>
