import { DEFAULT_LOCALE } from '@/plugins/i18n'
import { LOCALES } from '@/model/locales'
import store from '@/store'
import { namespacer } from '@/util/namespacer'
import { GET_LOCALE } from '@/store/auth/getter-types'
import { CHANGE_LOCALE } from '@/store/auth/action-types'
import { browserLocale } from '@/util/locale-detection'

const specialPages = ['login', 'password-reset-request', 'password-reset-submit', 'error']

export const localeFor = (to, from, authLocale, browserLocale) => {
    if (specialPages.includes(to) && from === null) {
        return LOCALES.includes(browserLocale) ? browserLocale : DEFAULT_LOCALE
    }

    return authLocale
}

export const multilang = (to, from, next) => {
    const authLocale = store.getters[namespacer('auth', GET_LOCALE)]
    const browserLanguage = browserLocale()
    const targetLocale = localeFor(to.name, from.name, authLocale, browserLanguage)

    if (targetLocale !== authLocale) {
        store
            .dispatch(namespacer('auth', CHANGE_LOCALE), targetLocale)
            .then(() => next())
    } else {
        next()
    }
}
