import ellipsize from 'ellipsize'
import { Component, Vue } from 'vue-property-decorator'

@Component
export default class TextMixin extends Vue {
    paragrapher (value) {
        return typeof value === 'string'
            ? '<p>' + value.replace(/(?:\r\n|\r|\n)/g, '</p><p>') + '</p>'
            : ''
    }

    truncate (value, after) {
        return ellipsize(value, after, { chars: [' ', '&', ',', '.'], ellipse: '...' })
    }

    abridgeText (value) {
        const splitted = value.split('</p>', 1)
        return splitted[0].startsWith('<p>')
            ? splitted[0] + '</p>'
            : splitted[0]
    }

    conditionalParagrapher (value) {
        return typeof value === 'string' && value.startsWith('<p>')
            ? value
            : this.paragrapher(value)
    }

    replaceNonBreakingWhiteSpace (text) {
        const re = /&nbsp;/g
        return text.replace(re, ' ')
    }
}
