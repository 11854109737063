
import { Component, Prop, VModel, Vue, Watch } from 'vue-property-decorator'
import CriteriaSetLoader from '@/components/criteria-set/criteria-set-loader.vue'
import LoadingCircle from '@/components/loading-circle/loading-circle.vue'
import CriteriaSetSelection from '@/components/criteria-set/criteria-set-selection.vue'
import { CreateTrendRadarInput, CriteriaSet } from '@/graphql/app/types'
import TiptapEditor from '@/components/form/editor/tiptap-editor.vue'
import { required } from 'vuelidate/lib/validators'

@Component({
    components: {
        TiptapEditor,
        CriteriaSetSelection,
        LoadingCircle,
        CriteriaSetLoader
    },
    validations: {
        trendradar: {
            title: {
                required
            },
            description: {},
            criteriaSetId: {
                required
            }
        }
    }
})
export default class TrendradarCreateDialog extends Vue {
  @VModel() trendradar!: CreateTrendRadarInput
  @Prop({ required: true }) criteriaSets!: Array<CriteriaSet>
  @Prop({ default: () => false }) pending!: boolean

  errors (token: string): Array<string> {
      return this.$v.trendradar[token]?.$dirty && this.$v.trendradar[token]?.$invalid
          ? [this.t(`form.errors.${token}`)]
          : []
  }

  t (token: string): string {
      const prefix = 'components.trendradar.create'
      return this.$t(`${prefix}.${token}`).toString()
  }

  onSubmit () {
      this.$emit('submit', { ...this.trendradar })
  }

  @Watch('trendradar')
  onChangeValue () {
      this.init()
  }

  initCriteriaSet (value: Array<CriteriaSet>): void {
      if (value.length >= 1) {
          this.trendradar.criteriaSetId = value[0].id
      }
  }

  init () {
      this.$v.$reset()
      this.initCriteriaSet(this.criteriaSets)
  }

  mounted () {
      this.init()
  }
}
