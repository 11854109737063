import { appClient } from '@/plugins/apollo'
import { IndustriesDocument, IndustriesQuery } from '@/graphql/app/types'

export interface Industry {
  id: string
  title: string
}

export async function industries (locale: string): Promise<Array<Industry>> {
    const result = await appClient.query<IndustriesQuery>({
        query: IndustriesDocument,
        variables: {
            locale
        }
    })

    return result.data.industries.map(industry => ({
        id: industry.id,
        title: (industry.translations || [])[0]?.title || ''
    }))
}
