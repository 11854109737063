import { DEFAULT_SNACKBAR_TIMEOUT } from '@/model/constants'
import { WORKSPACE } from '@/model/trendradar/tabs'
import { DEFAULTS as CONFIGS } from '@/model/views/configs'
import { PAGINATION } from '@/model/views/pagination'

export const initAdd = () => ({
    workspaces: [{
        id: null,
        type: null
    }],
    innovationField: [{
        id: null,
        type: null
    }]
})

export const initCreate = () => ({
    searchAgent: false,
    trendradarRelated: {
        show: false,
        tab: WORKSPACE,
        context: null
    }
})

export const initSnackbar = () => ({
    show: false,
    timeout: DEFAULT_SNACKBAR_TIMEOUT,
    mode: 'feedback',
    targets: [], // [{ to: {... }, title: 'xxx' }]
    messages: []
})

export const initProposeInnovationProject = (init = false) => ({
    show: init
})

export const initPin = (ids = null) => ({
    microtrendIds: ids ? [...ids] : []
})

export const initViewConfig = () => {
    const configs = {}
    Object.keys(CONFIGS).forEach(idx => {
        configs[idx] = CONFIGS[idx].map(elem => elem.component)
    })

    return {
        views: {},
        pagination: PAGINATION,
        configs
    }
}

export const getDefaultState = () => ({
    add: initAdd(),
    create: initCreate(),
    microtrend: null,
    snackbar: initSnackbar(),
    proposeInnovationProject: initProposeInnovationProject(),
    pin: initPin(),
    viewConfig: initViewConfig(),
    backUrl: null,
    featureUpdateDialog: true,
    universeUpdateDialog: false,
    workspaceDeprecatedDialog: false
})
