import { ApiError } from '@/error-handler/types'
import { translateCodeToMessage } from '@/error-handler/i18n'

export const extractNetworkError = (networkError: any): Array<ApiError> => {
    if (networkError && networkError.statusCode) {
        return [{
            code: networkError.statusCode,
            messages: [translateCodeToMessage(networkError.statusCode, 'graphql.errorCodes')]
        }]
    }

    return []
}
