export const PAGE_SIZE = 24
export const PAGER_VISIBLE = 7

export const DEFAULT_VIDEO_OPTIONS = {
    height: '380',
    autoplay: false,
    muted: false,
    language: 'de',
    playbackRates: [0.7, 1.0, 1.5, 2.0]
}

export const DEFAULT_SNACKBAR_TIMEOUT = 6000

export const ADMIN_PAGER_DEFAULTS = {
    pageSize: 10,
    page: 1
}

export const OVERRIDE_PAGINATION_THRESHOLD = 4
export const OVERRIDE_PAGINATION_PAGE_SIZE = 3
