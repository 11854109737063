import * as ACTION from './action-types'
import { SET_SCRAPER, SET_SCRAPED_INFORMATION } from './mutation-types'
import { initScrapedInformation } from './state'

export default {
    [ACTION.OPEN_SCRAPER]: ({ commit }) => {
        commit(SET_SCRAPED_INFORMATION, initScrapedInformation())
        commit(SET_SCRAPER, true)
    },

    [ACTION.CLOSE_SCRAPER]: ({ commit }) => {
        commit(SET_SCRAPER, false)
    }
}
