
import { Component, Vue, Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { HIDE_EXPORT_DIALOG } from '@/store/exports/mutation-types'
import { PDF, PPTX } from '@/model/export/formats'

// @ts-ignore
import exportGQL from '@/graphql/app/export/mutation/export.gql'
// @ts-ignore
import { EXPORT_DIALOG, EXPORT_NODE_ID, EXPORT_TITLE, EXPORT_TYPE } from '@/store/exports/getter-types'
import { GET_EXPORT_TEMPLATES } from '@/store/auth/getter-types'
import { HideExportDialog } from '@/store/exports/types'
import { ExportMutation, ExportTemplate, ExportType } from '@/graphql/app/types'
import { TriggerExportInput } from '@/ui/export-dialog/export-dialog.types'

import DialogWithPolling from '@/components/export/dialog-with-polling.vue'

const exportStore = namespace('exports')
const authStore = namespace('auth')

@Component<GlobalExportDialog>({
    components: {
        DialogWithPolling
    }
})
export default class GlobalExportDialog extends Vue {
  @exportStore.Getter(EXPORT_DIALOG) dialog!: boolean
  @exportStore.Getter(EXPORT_NODE_ID) id!: string | null
  @exportStore.Getter(EXPORT_TITLE) title!: string | null
  @exportStore.Getter(EXPORT_TYPE) type!: string | null

  @exportStore.Mutation(HIDE_EXPORT_DIALOG) hide!: HideExportDialog

  @authStore.Getter(GET_EXPORT_TEMPLATES) allExportTemplates!: Array<ExportTemplate>

  rerenderCount = 0

  @Watch('type', { immediate: true })
  onTypeChange () {
      this.rerenderCount++
  }

  get exportTemplates () {
      return this.allExportTemplates
          .filter(value => value.exportTypes?.includes(this.type as ExportType))
          .map(value => ({
              text: value.title,
              value: value.id
          }))
  }

  async triggerExport (input: TriggerExportInput) {
      const result = await this.$apollo.mutate<ExportMutation>({
          mutation: exportGQL,
          variables: {
              input: {
                  id: this.id,
                  ...input
              }
          }
      })

      return result?.data?.export || null
  }

  get availableFormats (): string[] {
      return [PDF, PPTX]
  }
}
